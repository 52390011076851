import { randTexture } from '../textures';
import { randShip } from './randShip';
import { seed } from '../utils';
import { GenerativeTexture, ShipConfig } from '../types';

export const getSeed = (config: ShipConfig) =>
  config.layers
    .map(l => parseInt(l) + 1)
    .map(n => (n % 4294967296 === 0 ? 1 : n))
    .reduce((s, n) => {
      const v = (s * n) % 4294967296;
      return v === 0 ? 1 : v;
    }, 1);

export const shipBackground = (config: ShipConfig): GenerativeTexture => {
  seed(getSeed(config));
  // need to run this to match star ships nft
  randShip();
  return randTexture();
};
