import {
  rand,
  randBit,
  randInt,
  repeat,
  randColorString,
  add,
  seed,
} from '../utils';
import { randTexture } from '../textures';
import { PlanetTraits, Vector } from '../types';
import { randName } from './randName';
import { randMoon } from './randMoon';

export const randPlanet = (
  s: number,
  minRadius: number = 15,
  maxRadius: number = 45,
): PlanetTraits => {
  seed(s);
  const name = randName();
  const useRingTexture = randBit();
  const rFactor = rand();
  const r = minRadius + rFactor * (maxRadius - minRadius);
  const ir = rand(1.2, 1.8) * r;
  const or = rand(1.1, 1.5) * ir;
  const rings = rFactor > 0.5;

  return {
    name,
    radius: r,
    segments: [randInt(9, 18), randInt(9, 18)],
    rotation: add(repeat(3, () => rand(-Math.PI / 4, Math.PI / 4)) as Vector, [
      Math.PI / 2,
      0,
      0,
    ]),
    texture: randTexture(),
    ringTexture: rings && useRingTexture ? randTexture() : undefined,
    ringColor: rings && !useRingTexture ? randColorString() : undefined,
    ringRadii: [ir, or],
    ringSegments: randInt(16, 38),
    moons: repeat(randInt(0, 4, 2), () =>
      randMoon(r / 8, r / 4, 1.25 * or, 2 * or),
    ),
    background: randTexture(),
  };
};
