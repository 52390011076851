import React, { FC } from 'react';
import { Button } from '.';

export type LoadingIndicatorProps = {
  error: boolean;
  style?: any;
};

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  error,
  style,
}) => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
        fontSize: '4rem',
        ...style,
      }}
    >
      <div>{error ? 'error' : '...'}</div>
      {error && (
        <Button onClick={() => window.location.reload()}>Refresh</Button>
      )}
    </div>
  );
};
