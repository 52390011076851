import { useRef, useLayoutEffect, useState, MutableRefObject } from 'react';

export const useResizeObserver = <T extends Element>(): [
  MutableRefObject<T | null>,
  DOMRect | null,
] => {
  const elementRef = useRef<T>(null);
  const [rect, setRect] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (elementRef.current === null) throw new Error();
    const { current } = elementRef;
    const observer = new ResizeObserver(() => {
      const rect = current.getBoundingClientRect();
      setRect(rect);
    });
    observer.observe(current);
    return () => observer.disconnect();
  }, [setRect, elementRef.current]);

  return [elementRef, rect];
};
