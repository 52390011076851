import React, { FC } from 'react';
import { CanvasBackground, Lights, Planet } from '../../components';
import { PlanetTraits } from '../../types';
import { useCamera } from '../../utils';

export type SceneProps = {
  planet: PlanetTraits;
};

export const Scene: FC<SceneProps> = ({ planet }) => {
  useCamera([0, -120, 0], [0, 0, 0], 60);
  return (
    <>
      <Lights />
      <CanvasBackground texture={planet.background} />
      <Planet {...planet} position={[0, 0, 0]} />
    </>
  );
};
