import React, { FC, useEffect } from 'react';
import { dataStore } from '../../dataStore';
import { PlanetData, ShipData, ShipStatus } from '../../dataStore/types';
import { ChooseShipModal } from './ChooseShipModal';
import BigNumber from 'bignumber.js';
import { Button } from '../../components';
import { EXCHANGE_URL } from '../../config';

export type PirateModalProps = {
  planet: PlanetData;
  close: () => void;
};

export const PirateModal: FC<PirateModalProps> = ({ planet, close }) => {
  const [chosenShip, updateChosenShip] = React.useState<ShipData | null>(null);
  const [costToPirate, updateCostToPirate] = React.useState<{
    raw: string;
    display: string;
  } | null>(null);
  const [approvalRequired, updateApprovalRequired] = React.useState(false);
  const [el69Required, updateEl69Required] = React.useState(false);

  useEffect(() => {
    if (chosenShip) {
      const fn = async () => {
        const pirateCost = await dataStore.pirateCost(chosenShip.id, planet.id);
        const approvedAmount = await dataStore.getApprovedAmount(
          dataStore.viewerAddress() as string,
        );
        if (
          new BigNumber(pirateCost.toString()).gt(approvedAmount.toString())
        ) {
          updateApprovalRequired(true);
          // Show approve dialogue
        } else {
          updateCostToPirate({
            raw: pirateCost.toString(),
            display: new BigNumber(pirateCost.toString())
              .div(10 ** 18)
              .toFixed(4, 1),
          });
        }
      };
      fn();
    }
  }, [chosenShip]);
  const showPrice = chosenShip && costToPirate;
  return (
    <ChooseShipModal
      title={`Pirate ${planet.traits.name}:`}
      close={close}
      loadShips={async () => {
        const address = dataStore.viewerAddress();
        if (address === null) return [];
        const ships = await dataStore.enabledShips(address);
        return ships.filter(
          ship =>
            ship.currentPlanet?.id === planet.id &&
            ship.status === ShipStatus.ACTIVE,
        );
      }}
      onSelect={ship => {
        updateChosenShip(ship);
        // TODO: need to bring pirate cost into this component, refactor choose
        // ship modal to accept message directly as a prop instead of of promise
        // dataStore.pirate(ship.id, planet.id, 0, 0);
        // close();
      }}
    >
      {!chosenShip && 'Choose ship to see cost to pirate.'}
      {chosenShip && !costToPirate && !approvalRequired && '...'}
      {approvalRequired && !el69Required && (
        <>
          <div style={{ marginBottom: '1rem' }}>
            You must first approve the game contract to transfer EL69 on your
            behalf.
          </div>
          <Button
            onClick={async () => {
              await dataStore.approveSpend();
              close();
            }}
          >
            Approve
          </Button>
        </>
      )}
      {el69Required && (
        <div>
          You do not have enough EL69 to attempt to pirate. The current cost is{' '}
          {costToPirate?.display}.
          <div style={{ marginTop: '1rem' }}>
            <a href={EXCHANGE_URL} target="_blank" rel="noreferrer">
              Get EL69 on Uniswap
            </a>
          </div>
        </div>
      )}
      {showPrice && !el69Required && (
        <>
          <div
            style={{ marginBottom: '1rem' }}
          >{`Cost to attempt pirate: ${costToPirate.display} $EL69`}</div>
          <Button
            onClick={async () => {
              const balance = await dataStore.walletEL69Balance(
                dataStore.viewerAddress() as string,
              );
              if (new BigNumber(balance).times(10 ** 18).lt(costToPirate.raw)) {
                return updateEl69Required(true);
              }
              dataStore.pirate(chosenShip.id, planet.id, costToPirate.raw);
              close();
            }}
          >
            Attempt Pirate
          </Button>
        </>
      )}
    </ChooseShipModal>
  );
};
