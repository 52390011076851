import React, { FC } from 'react';
import { Link } from '../../components';

export type NotFoundPageProps = {};

export const NotFoundPage: FC<NotFoundPageProps> = () => (
  <div style={{ margin: 48, fontSize: '36px' }}>
    <h1 style={{ fontSize: '96px' }}>404</h1>
    <Link href="/">home</Link>
  </div>
);
