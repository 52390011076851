import { Canvas } from '@react-three/fiber';
import React, { FC, ReactNode } from 'react';
import { StatusBarPageLayout } from './StatusBarPageLayout';
import styled from 'styled-components';

export type EntityPageLayoutProps = {
  scene: ReactNode;
  content: ReactNode;
  breadcrumbs: [string, string | null][];
  entityName: string;
};

const Grid = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: minMax(20rem, 1fr) 2fr;
  box-sizing: border-box;
  font-size: 2rem;
  @media (max-width: 45rem) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;
const LeftCol = styled.div`
  color: #fff;
  padding: 2rem 2rem 6rem;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 45rem) {
    padding-top: 0;
    gap: 1rem;
  }
`;
const RightCol = styled.div`
  position: relative;
  @media (max-width: 45rem) {
    grid-row: 1;
    aspect-ratio: 1;
  }
`;
const RightContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
const Heading = styled.h1`
  font-size: 3em;
  margin: 0;
`;

export const EntityPageLayout: FC<EntityPageLayoutProps> = ({
  scene,
  content,
  breadcrumbs,
  entityName,
}) => {
  return (
    <StatusBarPageLayout
      breadcrumbs={breadcrumbs}
      content={
        <Grid>
          <LeftCol>
            <Heading>{entityName}</Heading>
            {content}
          </LeftCol>
          <RightCol>
            <RightContent>
              <Canvas>{scene}</Canvas>
            </RightContent>
          </RightCol>
        </Grid>
      }
    />
  );
};
