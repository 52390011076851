import { randTexture } from '../textures';
import { ShipConfig } from '../types';
import { mockSectorPlanets } from './mockPlanets';
import { ShipData, ShipStatus } from './types';

const mockShipConfigs: ShipConfig[] = [
  {
    params: {
      blastersLength: '12',
      blastersWidth: '4',
      bodyLength: '8',
      bodyWidth: '9',
      color1: 'rgb(114,114,114)',
      color2: 'rgb(109,90,113)',
      color3: 'rgb(28,8,70)',
      color4: 'rgb(68,168,80)',
    },
    layers: [
      '3765439494',
      '260491747805576036352',
      '540897443840',
      '219848651568',
      '113163529879552',
    ],
  },
  {
    params: {
      blastersLength: '14',
      blastersWidth: '4',
      bodyLength: '9',
      bodyWidth: '8',
      color1: 'rgb(188,188,188)',
      color2: 'rgb(205,137,121)',
      color3: 'rgb(136,39,121)',
      color4: 'rgb(34,41,220)',
    },
    layers: [
      '127298993357960119822',
      '120729109532',
      '225125005787136',
      '225179910270976',
      '481111',
    ],
  },
  {
    params: {
      blastersLength: '14',
      blastersWidth: '5',
      bodyLength: '10',
      bodyWidth: '10',
      color1: 'rgb(157,157,157)',
      color2: 'rgb(90,117,107)',
      color3: 'rgb(144,89,106)',
      color4: 'rgb(154,147,97)',
    },
    layers: [
      '29971919360288003784381038592',
      '594714698799633102872093376752',
      '138543104',
      '0',
      '18872536231457152',
    ],
  },

  {
    params: {
      blastersLength: '15',
      blastersWidth: '3',
      bodyLength: '10',
      bodyWidth: '10',

      color1: 'rgb(70,70,70)',
      color2: 'rgb(200,50,20)',
      color3: 'rgb(179,219,248)',
      color4: 'rgb(85,235,190)',
    },
    layers: [
      '3775532003054281348349955075',
      '951667312788550739015047867422',
      '0',
      '0',
      '5963776',
    ],
  },
  {
    params: {
      blastersLength: '14',
      blastersWidth: '4',
      bodyLength: '8',
      bodyWidth: '8',

      color1: 'rgb(9,9,9)',
      color2: 'rgb(93,179,85)',
      color3: 'rgb(254,201,19)',
      color4: 'rgb(177,169,252)',
    },
    layers: [
      '63296685387821104',
      '6946828916979924992',
      '14018779938816',
      '0',
      '3588805953063142',
    ],
  },
  {
    params: {
      blastersLength: '14',
      blastersWidth: '4',
      bodyLength: '9',
      bodyWidth: '9',

      color1: 'rgb(6,6,6)',
      color2: 'rgb(131,107,202)',
      color3: 'rgb(16,134,164)',
      color4: 'rgb(189,87,158)',
    },
    layers: [
      '6768593580589056',
      '277241593060927733760',
      '0',
      '421443665920',
      '60847660662784',
    ],
  },
  {
    params: {
      blastersLength: '15',
      blastersWidth: '3',
      bodyLength: '8',
      bodyWidth: '10',

      color1: 'rgb(181,181,181)',
      color2: 'rgb(125,125,203)',
      color3: 'rgb(247,172,114)',
      color4: 'rgb(46,175,83)',
    },
    layers: [
      '907579808427601842921664',
      '1163279783749811712000',
      '27',
      '14843409039360',
      '13824',
    ],
  },
  {
    params: {
      blastersLength: '14',
      blastersWidth: '3',
      bodyLength: '9',
      bodyWidth: '8',

      color1: 'rgb(192,192,192)',
      color2: 'rgb(218,123,116)',
      color3: 'rgb(218,214,81)',
      color4: 'rgb(65,29,172)',
    },
    layers: ['255657728', '4428197204224', '432', '1728', '235149459456'],
  },
  {
    params: {
      blastersLength: '14',
      blastersWidth: '4',
      bodyLength: '10',
      bodyWidth: '9',

      color1: 'rgb(7,7,7)',
      color2: 'rgb(207,101,19)',
      color3: 'rgb(241,236,3)',
      color4: 'rgb(173,25,163)',
    },
    layers: [
      '151329392830621704240',
      '12312',
      '3504708911120',
      '33063487173296640',
      '0',
    ],
  },
  {
    params: {
      blastersLength: '14',
      blastersWidth: '3',
      bodyLength: '9',
      bodyWidth: '10',

      color1: 'rgb(96,96,96)',
      color2: 'rgb(136,6,57)',
      color3: 'rgb(187,235,185)',
      color4: 'rgb(85,2,177)',
    },
    layers: [
      '67993075783003987515646072',
      '464680861914373089027686400',
      '231928233984',
      '235149462912',
      '455409078',
    ],
  },
  {
    params: {
      blastersLength: '14',
      blastersWidth: '5',
      bodyLength: '10',
      bodyWidth: '10',

      color1: 'rgb(154,154,154)',
      color2: 'rgb(20,120,38)',
      color3: 'rgb(177,56,252)',
      color4: 'rgb(222,186,168)',
    },
    layers: [
      '3630322707114950022660096',
      '475911557212026014523903208472',
      '0',
      '425201762304',
      '298837471360188416',
    ],
  },
  {
    params: {
      blastersLength: '14',
      blastersWidth: '4',
      bodyLength: '10',
      bodyWidth: '8',

      color1: 'rgb(5,5,5)',
      color2: 'rgb(55,77,212)',
      color3: 'rgb(199,226,69)',
      color4: 'rgb(69,170,169)',
    },
    layers: [
      '2097152',
      '4557361347922231296',
      '57533045438350944',
      '57478052727226368',
      '65280',
    ],
  },
  {
    params: {
      blastersLength: '13',
      blastersWidth: '4',
      bodyLength: '10',
      bodyWidth: '10',

      color1: 'rgb(59,59,59)',
      color2: 'rgb(110,216,94)',
      color3: 'rgb(79,244,140)',
      color4: 'rgb(253,29,173)',
    },
    layers: [
      '37169641524459962165550120960',
      '1161551851799817374418337792',
      '113149003117363',
      '112205160775680',
      '0',
    ],
  },
];

export const mockShips: ShipData[] = mockShipConfigs.map((shipConfig, i) => ({
  id: `${i + 1}`,
  currentPlanet: mockSectorPlanets[i % mockSectorPlanets.length],
  location: [-50 + Math.random() * 100, -50 + Math.random() * 100],
  shipConfig,
  owner: '0x5C14Ba32530d76019a0fFDe7E98c9BD32b4B6b02',
  enabledForGameplay: true,
  unclaimedRewards: '999',
  status: ShipStatus.ACTIVE,
  stats: {
    speed: '4000',
    fuelEfficiency: '4000',
    toolStrength: '4000',
    labCapacity: '4000',
    laserStrength: '4000',
    groundWeapons: '4000',
    spaceWeapons: '4000',
  },
  background: randTexture(),
}));
