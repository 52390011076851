import React, { FC, useEffect, useRef } from 'react';
import { GenerativeTexture } from '../../types';

export type TextureSectionProps = {
  texture: () => GenerativeTexture;
  children: any;
  id: string;
};

export const TextureSection: FC<TextureSectionProps> = ({
  texture,
  children,
  id,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    const gt = texture();
    gt.canvas.style.imageRendering = 'pixelated';
    const onResize = () => {
      if (ref.current) {
        gt.canvas.style.width = `${ref.current.offsetWidth}px`;
        gt.canvas.style.height = `${ref.current.offsetHeight}px`;
      }
    };
    onResize();
    window.addEventListener('resize', onResize);

    ref.current.appendChild(gt.canvas);

    let alive = true;
    let last = Date.now();
    const step = () => {
      const now = Date.now();
      const delta = (now - last) / 1000;
      last = now;
      gt.step(delta);
      if (alive) requestAnimationFrame(step);
    };

    requestAnimationFrame(step);
    return () => {
      alive = false;
      window.removeEventListener('resize', onResize);
    };
  }, [texture]);

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '18px',
        padding: '36px 0',
        boxSizing: 'border-box',
      }}
      id={id}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
        ref={ref}
      />
      {children}
    </div>
  );
};
