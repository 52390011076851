import React, { FC, useContext, useEffect, useState } from 'react';
import {
  ApproveTransferModal,
  Button,
  Link,
  PlanetList,
  EnableShipModal,
  EntityPageLayout,
  LoadingIndicator,
  TraitBar,
  Modal,
} from '../../components';
import { TxModalContext } from '../../App';
import { Web3Context } from '../../contexts/Web3Context';
import { dataStore } from '../../dataStore';
import { PlanetData, ShipStatus } from '../../dataStore/types';
import { shortAddress } from '../../utils';
import { useAsyncData } from '../../utils/useAsyncData';
import { web3Util } from '../../utils/web3Utils';
import { Scene } from './Scene';
import BN, { BigNumber } from 'bignumber.js';
import { RoutingContext } from '../../contexts';
import { getContract, getPlanetDataById } from '../../dataStore/Store';
import { TravelModal, TravelModalProps } from '../PlanetPage/TravelModal';
import { EL69_CONTRACT, GAME_DATA_CONTRACT } from '../../config';

export type ShipPageProps = {
  path: string[];
};

type TravelCost = {
  distance: string;
  costPerDistance: string;
  cost: string;
  displayCost: string;
};

export const ShipPage: FC<ShipPageProps> = ({ path: [, idString] }) => {
  const id = idString;
  const { address } = useContext(Web3Context);
  const { close, nonce } = useContext(TxModalContext);
  const router = useContext(RoutingContext);
  const [approvalRequired, updateApprovalRequired] = React.useState(false);
  const [chosenPlanetId, updateChosenPlanetId] = React.useState<
    string | undefined
  >();
  const [travelCosts, updateTravelCosts] = useState<TravelCost | undefined>();
  const [enableModalActive, setEnableModalActive] = useState(false);
  const [reentryPlanetId, updateReentryPlanet] = useState('');
  const [approveModalActive, setApproveModalActive] = useState(false);
  const [travelModalActive, setTravelModalActive] = useState(false);
  const [defaultTravelCosts, updateDefaultTravelCosts] =
    useState<TravelModalProps['defaultCosts']>();
  const [travelToPlanetData, updatePlanetData] = useState<PlanetData | null>(
    null,
  );

  const [targetPlanet, setTargetPlanet] = useState<PlanetData | null>(null);
  const [approveHash, updateApproveHash] = useState<string>('');

  const [loading, error, data] = useAsyncData(
    () => Promise.all([dataStore.ship(id), dataStore.shipMetadata(id)]),
    [id, nonce],
  );

  const [enabled, updateEnabled] = useState(data?.[0]?.enabledForGameplay);

  useEffect(() => {
    if (approveHash) {
      web3Util.pollTx?.once('completed', txHash => {
        if (txHash === approveHash) {
          close();
          setEnableModalActive(true);
        }
      });
    }
  }, [approveHash]);

  if (loading || error || data === null) {
    return <LoadingIndicator error={error} />;
  }

  const [ship, nftData] = data;
  const shipEnabledForGameplay = ship.enabledForGameplay || enabled;

  const viewerIsOwner = address.toLowerCase() === ship.owner.toLowerCase();
  const viewerIsPlanetOwner =
    ship.currentPlanet?.owner.toLowerCase() === address.toLowerCase();

  const rewards = new BN(
    (ship?.unclaimedRewards && +ship?.unclaimedRewards > 0
      ? web3Util.web3?.utils.fromWei(ship?.unclaimedRewards)
      : '0') as string,
  );

  return (
    <>
      <EntityPageLayout
        breadcrumbs={[
          ['INVADERS!', '/starmap'],
          ['Ships', null],
          [`${id}`, `/ships/${id}`],
        ]}
        scene={<Scene ship={ship} />}
        entityName={`Star Ship #${id}`}
        content={
          <>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                gap: '1rem',
              }}
            >
              <div>
                OWNER:{' '}
                <a href={`/wallets/${ship.owner}`}>
                  {shortAddress(ship.owner)}
                </a>
              </div>
              <div>
                STATUS:{' '}
                {shipEnabledForGameplay
                  ? ship.status
                  : 'Ship not enabled for gameplay'}
              </div>
              {!!ship.disabledUntil && (
                <>
                  <div>DISABLED UNTIL: {ship.disabledUntil}</div>
                  <div>CURRENT TIME: {ship.currentTime}</div>
                </>
              )}
              {ship.status === 'MINING' && (
                <div>
                  <hr />
                  <div style={{ marginBottom: '1rem' }}>
                    Extractable EL69 Harvest:
                  </div>
                  <div>Guaranteed: {rewards.div(2).toFixed(3)} EL69</div>
                  <div>Max Bonus: {rewards.toFixed(3)} EL69</div>
                </div>
              )}
            </div>
            <div>
              <h3>LOCATION</h3>
              {ship.currentPlanet ? (
                <Link href={`/planets/${ship.currentPlanet.id}`}>
                  <div
                    style={{
                      width: '10rem',
                      margin: '0 0 1rem',
                      background: '#111',
                    }}
                  >
                    <PlanetList
                      itemsPerRow={1}
                      planets={[ship.currentPlanet]}
                      targetPlanet={targetPlanet}
                      onHoverPlanet={planet => setTargetPlanet(planet)}
                      outline
                    />
                  </div>
                </Link>
              ) : (
                'N/A'
              )}
            </div>
            {viewerIsOwner && (
              <>
                <div>
                  <h3>ACTIONS</h3>
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: 'column',
                      gap: '1rem',
                    }}
                  >
                    {viewerIsPlanetOwner && ship.status === ShipStatus.ACTIVE && (
                      <>
                        {(ship.currentPlanet?.miningShipId == null ||
                          ship.currentPlanet?.miningShipId == '0') && (
                          <Button
                            onClick={() => {
                              dataStore.mine(ship.id);
                            }}
                          >
                            Mine
                          </Button>
                        )}
                        <Button
                          onClick={() => {
                            setTravelModalActive(true);
                          }}
                        >
                          Travel
                        </Button>
                      </>
                    )}
                    {ship.status === ShipStatus.MINING && (
                      <>
                        <Button
                          onClick={() => {
                            if (rewards.eq(0)) {
                              return alert('No EL69 to extract');
                            }
                            dataStore.withdrawMiningRewards(ship.id, false);
                          }}
                        >
                          Extract EL69
                        </Button>
                        <Button
                          onClick={() => {
                            if (rewards.eq(0)) {
                              return alert('No EL69 to extract');
                            }
                            dataStore.withdrawMiningRewards(ship.id, true);
                          }}
                        >
                          Risky Extract EL69
                        </Button>
                        <Button
                          onClick={() => {
                            dataStore.stopMining(ship.id);
                          }}
                        >
                          Stop Mining
                        </Button>
                      </>
                    )}
                    {shipEnabledForGameplay ? (
                      <Button
                        onClick={async () => {
                          await dataStore.disableShipForGameplay(id);
                          router.route(`/wallets/${address}`);
                        }}
                      >
                        Disable for gameplay
                      </Button>
                    ) : (
                      <Button
                        onClick={async () => {
                          const isApproved = await dataStore.areShipsApproved();
                          if (isApproved) {
                            setEnableModalActive(true);
                          } else {
                            setApproveModalActive(true);
                          }
                        }}
                      >
                        Enable for gameplay
                      </Button>
                    )}
                  </div>
                </div>
              </>
            )}
            <div>
              <h3>STATS</h3>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '1rem',
                  fontSize: '1.5rem',
                }}
              >
                <TraitBar label="Speed" value={parseInt(ship.stats.speed)} />
                <TraitBar
                  label="Fuel efficiency"
                  value={parseInt(ship.stats.fuelEfficiency)}
                />
                <TraitBar
                  label="Tool strength"
                  value={parseInt(ship.stats.toolStrength)}
                />
                <TraitBar
                  label="Lab capacity"
                  value={parseInt(ship.stats.labCapacity)}
                />
                <TraitBar
                  label="Laser strength"
                  value={parseInt(ship.stats.laserStrength)}
                />
                <TraitBar
                  label="Ground weapons"
                  value={parseInt(ship.stats.groundWeapons)}
                />
                <TraitBar
                  label="Space weapons"
                  value={parseInt(ship.stats.spaceWeapons)}
                />
              </div>
            </div>
            {nftData?.animation_url && (
              <a
                target="_blank"
                style={{ textDecoration: 'none' }}
                href={nftData?.animation_url}
                rel="noreferrer"
              >
                <Button style={{ textDecoration: 'none', marginTop: '1rem' }}>
                  Play Minigame
                </Button>
              </a>
            )}
          </>
        }
      />
      {travelCosts && (
        <Modal
          close={() => updateTravelCosts(void 0)}
          title={`Fee for reentry`}
        >
          <p>
            This ship is currently on planet #{reentryPlanetId}. To enable ship
            without a fee land on that planet.
          </p>
          <p>
            To land on your planet #{chosenPlanetId} you must pay{' '}
            {travelCosts.displayCost} EL69.
          </p>
          {approvalRequired && (
            <Button
              onClick={async () => {
                const hash = await dataStore.beginTransaction(
                  EL69_CONTRACT,
                  'approve',
                  [
                    GAME_DATA_CONTRACT,
                    new BigNumber(99999999999).times(10 ** 18).toFixed(),
                  ],
                );
                web3Util.pollTx?.once('completed', txHash => {
                  if (txHash === hash) {
                    close();
                    updateApprovalRequired(false);
                  }
                });
              }}
            >
              Approve EL69
            </Button>
          )}
          {!approvalRequired && (
            <Button
              onClick={async () => {
                const hash = await dataStore.enableShipForGameplay(
                  ship.id,
                  chosenPlanetId as unknown as string,
                );
                web3Util.pollTx?.once('completed', txHash => {
                  if (txHash === hash) {
                    close();
                    updateEnabled(true);
                    updateTravelCosts(void 0);
                  }
                });
              }}
            >
              Pay and Continue
            </Button>
          )}
        </Modal>
      )}
      {enableModalActive && !travelCosts && (
        <EnableShipModal
          onSubmit={async (shipId, planetId) => {
            const data = getContract(GAME_DATA_CONTRACT);
            const reentryPlanet = await data.methods
              .reentryLocation(shipId)
              .call();
            updateChosenPlanetId(planetId);
            if (reentryPlanet.toString() != '0') {
              const travelCost = await dataStore.travelCost(
                reentryPlanet,
                planetId,
              );
              const el69 = getContract(EL69_CONTRACT);
              const allowance = await el69.methods
                .allowance(dataStore.viewerAddress(), GAME_DATA_CONTRACT)
                .call();
              if (new BigNumber(allowance.toString()).lt(travelCost.cost)) {
                updateApprovalRequired(true);
              }
              updateReentryPlanet(reentryPlanet.toString());
              updateTravelCosts(travelCost);
              return;
              // get cost for reentry
            }

            const hash = await dataStore.enableShipForGameplay(
              shipId,
              planetId,
            );
            web3Util.pollTx?.once('completed', txHash => {
              if (txHash === hash) {
                close();
                updateEnabled(true);
              }
            });
          }}
          close={() => setEnableModalActive(false)}
          shipId={ship.id}
        />
      )}
      {travelModalActive && (
        <EnableShipModal
          onSubmit={async (_, planetId) => {
            const planetData = await getPlanetDataById(planetId);
            if (!planetData?.enabledForGameplay) {
              alert('Planet not enabled for gameplay. Cannot land.');
              return;
            }
            const travelCost = await dataStore.travelCost(
              ship?.currentPlanet?.id as string,
              planetId,
            );
            updateDefaultTravelCosts(travelCost);
            updatePlanetData(planetData);
          }}
          close={() => setTravelModalActive(false)}
          shipId={ship.id}
        />
      )}
      {travelToPlanetData && (
        <TravelModal
          close={() => updatePlanetData(null)}
          defaultChosenShip={ship}
          defaultCosts={defaultTravelCosts}
          planet={travelToPlanetData}
        />
      )}
      {approveModalActive && (
        <ApproveTransferModal
          close={() => setApproveModalActive(false)}
          transferItem="Star Ship NFTs"
          onConfirm={async () => {
            const hash = await dataStore.approveShips();
            setApproveModalActive(false);
            updateApproveHash(hash);
          }}
        />
      )}
    </>
  );
};
