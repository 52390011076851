import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Modal } from '.';
import { POLYGONSCAN_URI } from '../config';
import { shortAddress } from '../utils';

type TxModalProps = {
  transactions: string[];
  close: () => void;
};

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 1rem;
  margin-top: 1rem;
`;

export default function TxModal({ transactions, close }: TxModalProps) {
  const statuses: { [hash: string]: 'pending' | 'complete' } = useMemo(
    () => ({}),
    [],
  );

  // update statuses via mutation when transactions change
  Object.keys(statuses).forEach(hash => (statuses[hash] = 'complete'));
  transactions.forEach(hash => (statuses[hash] = 'pending'));

  return (
    <Modal close={close} title="Transaction submitted. Please wait.">
      <Wrap>
        <div>Hash</div>
        <div>Status</div>
        {Object.entries(statuses).map(([hash, status]) => (
          <React.Fragment key={hash}>
            <div>
              <a
                href={`${POLYGONSCAN_URI}${hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {shortAddress(hash)}
              </a>
            </div>
            <div>{status}</div>
          </React.Fragment>
        ))}
      </Wrap>
    </Modal>
  );
}
