import { useFrame, useThree } from '@react-three/fiber';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Vector2 } from 'three';

export type TargetOutlineProps = {
  selectedObjects: any[];
};

export const TargetOutline: FC<TargetOutlineProps> = ({ selectedObjects }) => {
  const { gl, scene, camera, size } = useThree();
  const composer = useRef<any>();
  const aspect = useMemo(() => new Vector2(size.width, size.height), [size]);
  useEffect(() => composer.current.setSize(size.width, size.height), [size]);
  useFrame(() => composer.current.render(), 1);

  return (
    // @ts-ignore
    React.createElement('effectComposer', { ref: composer, args: [gl] }, [
      // @ts-ignore
      <renderPass attachArray="passes" args={[scene, camera]} />,
      // @ts-ignore
      <outlinePass
        attachArray="passes"
        args={[aspect, scene, camera]}
        selectedObjects={selectedObjects}
        visibleEdgeColor="white"
        edgeStrength={50}
        edgeThickness={0.5}
      />,
    ])
  );
};
