import { Canvas } from '@react-three/fiber';
import React, { FC, useContext, useMemo, useState } from 'react';
import { ShipList } from '../../components';
import { HoverInfoCard } from '../../components/HoverInfoCard';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { StatusBarPageLayout } from '../../components/StatusBarPageLayout';
import { RoutingContext } from '../../contexts';
import { dataStore } from '../../dataStore';
import { PlanetData, ShipData } from '../../dataStore/types';
import { useAsyncData } from '../../utils/useAsyncData';
import { SectorsScene } from './SectorsScene';

export type StarMapPageProps = {
  path: string[];
};

export const StarMapPage: FC<StarMapPageProps> = () => {
  const address = dataStore.viewerAddress();
  const { route } = useContext(RoutingContext);
  const [loading, error, data] = useAsyncData<
    [(PlanetData | null | undefined)[], ShipData[]]
  >(
    () =>
      address === null
        ? Promise.resolve([[], []])
        : Promise.all([
            dataStore.enabledPlanets(address),
            dataStore.enabledShips(address),
          ]),
    [address],
  );
  const [targetPlanet, setTargetPlanet] = useState<string | null>(null);
  const [targetSector, setTargetSector] = useState<number | null>(null);

  const targetPlanetData = useMemo<PlanetData | null>(() => {
    if (data) {
      const [planets] = data;
      return planets.find(p => p?.id === targetPlanet) ?? null;
    }
    return null;
  }, [data, targetPlanet]);

  const shipsByPlanet = useMemo<{ [planetId: string]: ShipData[] }>(() => {
    const map: { [planetId: string]: ShipData[] } = {};
    if (data) {
      const [_, ships] = data;
      ships.forEach(ship => {
        if (ship.currentPlanet) {
          map[ship.currentPlanet.id] = map[ship.currentPlanet.id] ?? [];
          map[ship.currentPlanet.id].push(ship);
        }
      });
    }
    return map;
  }, [data]);

  if (loading || error || data === null) {
    return <LoadingIndicator error={error} />;
  }

  const [planets, ships] = data;

  return (
    <StatusBarPageLayout
      breadcrumbs={[
        ['INVADERS!', '/starmap'],
        ['Star Map', null],
      ]}
      content={
        <>
          <Canvas>
            {data && (
              <SectorsScene
                planets={planets}
                ships={ships}
                viewSector={(sector: number) =>
                  route(`/starmap/sectors/${sector}`)
                }
                viewPlanet={(id: number) => route(`/planets/${id}`)}
                viewShip={(id: number) => route(`/ships/${id}`)}
                targetPlanet={targetPlanet}
                setTargetPlanet={setTargetPlanet}
                targetSector={targetSector}
                setTargetSector={setTargetSector}
              />
            )}
          </Canvas>
          {targetPlanet !== null && targetPlanetData && (
            <HoverInfoCard>
              <div>{targetPlanetData.traits.name}</div>
              <div>
                {targetPlanetData.location.map(n => n.toFixed(1)).join(', ')}
              </div>
              <div>
                Your ships:
                <div style={{ margin: '0 -1rem' }}>
                  <ShipList
                    ships={shipsByPlanet[targetPlanet] ?? []}
                    itemsPerRow={4}
                    targetShip={null}
                  />
                </div>
              </div>
            </HoverInfoCard>
          )}
          {targetSector !== null && (
            <HoverInfoCard>Sector #{targetSector}</HoverInfoCard>
          )}
        </>
      }
    />
  );
};
