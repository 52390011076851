import { CanvasTexture, UVMapping, RepeatWrapping, NearestFilter } from 'three';
import { GenerativeTexture } from '../types';
import { rand, randInt } from '../utils';

export const starfield = (): GenerativeTexture => {
  const width = Math.floor(rand(30, 90));
  const height = Math.floor(rand(30, 90));
  const minColor = randInt(0, 64);
  const speed = rand(0.6, 2);
  const numStars = Math.floor(rand(23, 97));
  const stars = new Array(numStars).fill(0).map(() => ({
    x: randInt(0, width),
    y: randInt(0, height),
    z: rand(0.2, 1),
    r: randInt(minColor, 256),
    g: randInt(minColor, 256),
    b: randInt(minColor, 256),
  }));

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  let arr;

  const draw = () => {
    arr = new Uint8ClampedArray(4 * width * height);
    for (let i = 3; i < arr.length; i += 4) {
      arr[i] = 255;
    }
    for (let i = 0; i < stars.length; i++) {
      const { x, y, r, g, b } = stars[i];
      const idx = (Math.floor(y) * width + x) * 4;
      arr[idx] = r;
      arr[idx + 1] = g;
      arr[idx + 2] = b;
    }
    ctx.putImageData(new ImageData(arr, width), 0, 0);
  };
  draw();

  const texture = new CanvasTexture(
    canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    NearestFilter,
    NearestFilter,
  );
  texture.repeat.x = rand(3.1, 6.1);
  texture.repeat.y = rand(1.1, 3.6);

  const frameTime = 1 / 30;
  let elapsed = 0;

  return {
    texture,
    canvas,
    step: delta => {
      elapsed += delta;
      if (elapsed > frameTime) {
        elapsed = elapsed % frameTime;
        for (let i = 0; i < numStars; i++) {
          stars[i].y = (stars[i].y + stars[i].z * speed * 2) % height;
        }
        draw();
        texture.needsUpdate = true;
      }
    },
    dark: true,
  };
};
