export const countNeighbors = (
  matrix: number[][],
  col: number,
  row: number,
) => {
  return (
    (matrix[row - 1]?.[col] || 0) +
    (matrix[row + 1]?.[col] || 0) +
    (matrix[row][col - 1] || 0) +
    (matrix[row][col + 1] || 0)
  );
};
