import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { Vector } from '../types';
import { PerspectiveCamera } from 'three';

export const useCamera = (
  position: Vector,
  target: Vector,
  fov: number,
  far: number = 5000,
): void => {
  const camera = useThree(state => state.camera);
  useEffect(() => {
    camera.position.x = position[0];
    camera.position.y = position[1];
    camera.position.z = position[2];
    camera.lookAt(...target);
    (camera as PerspectiveCamera).fov = fov;
    camera.far = far;
    camera.updateProjectionMatrix();
  }, [camera, position, fov, target, far]);
};
