import { Section } from './Section';
import styled from 'styled-components';

export const HoverInfoCard = styled(Section)`
  position: absolute;
  top: 0;
  right: 2rem;
  padding: 1rem 2rem;
  background: black;
  color: white;
  zindex: 999;
  display: flex;
  max-width: 15rem;
  flex-direction: column;
  gap: 0.5rem;
  pointer-events: none;
`;
