import React, { FC, useEffect, useMemo, useRef } from 'react';
import { CanvasPlane, Planet, TargetOutline } from '../../components';
import { sector } from '../../textures';
import { PlanetData, ShipData } from '../../dataStore/types';
import { MapControls } from 'three/examples/jsm/controls/OrbitControls';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { BackSide, OrthographicCamera, Vector3 } from 'three';

extend({ MapControls });

export type SectorSceneProps = {
  sectorId: number;
  planets: PlanetData[];
  ships: ShipData[];
  viewPlanet: (id: string) => void;
  targetPlanet: string | null;
  setTargetPlanet: (id: string | null) => void;
};

const minZoom = 1;
const maxZoom = 15;

const getRect = (
  aspectRatio: number,
  zoom: number,
): {
  left: number;
  right: number;
  top: number;
  bottom: number;
} => {
  let tHeight, tWidth;
  if (aspectRatio < 1) {
    tWidth = 60 / zoom;
    tHeight = tWidth / aspectRatio;
  } else {
    tHeight = 60 / zoom;
    tWidth = tHeight * aspectRatio;
  }
  return {
    left: -tWidth / 2,
    right: tWidth / 2,
    top: -tHeight / 2,
    bottom: tHeight / 2,
  };
};

export const SectorScene: FC<SectorSceneProps> = ({
  planets,
  viewPlanet,
  targetPlanet,
  setTargetPlanet,
}) => {
  const texture = useMemo(sector, []);
  const groupRef = useRef<any>();
  const planetsRef = useRef<{ [id: string]: any }>({});
  const meshRef = useRef<any>();
  const drag = useRef<{ position: Vector3 | null }>({
    position: null,
  });
  const zoom = useRef(1);
  useEffect(() => {
    document.body.style.overscrollBehavior = 'none';
    return () => {
      document.body.style.overscrollBehavior = 'auto';
    };
  }, []);
  useFrame(state => {
    const camera = state.camera as OrthographicCamera;
    const { width, height } = state.gl.domElement;
    const aspectRatio = width / height;
    const { left, right, top, bottom } = getRect(aspectRatio, zoom.current);
    camera.left = left;
    camera.right = right;
    camera.top = top;
    camera.bottom = bottom;
    camera.updateProjectionMatrix();
  });
  const three = useThree();

  return (
    <>
      <ambientLight intensity={1} />
      <mesh
        position={[0, 0, 0]}
        ref={meshRef}
        onPointerDown={e => {
          drag.current.position = e.point;
        }}
        onPointerMove={e => {
          if (drag.current.position) {
            groupRef.current.position.x -= drag.current.position.x - e.point.x;
            groupRef.current.position.y -= drag.current.position.y - e.point.y;
            drag.current.position = e.point;
          }
        }}
        onPointerUp={() => {
          drag.current.position = null;
        }}
        onWheel={e => {
          const { width, height } = three.gl.domElement;
          const aspectRatio = width / height;
          const {
            left: left0,
            right: right0,
            top: top0,
            bottom: bottom0,
          } = getRect(aspectRatio, zoom.current);

          zoom.current = Math.min(
            Math.max(zoom.current - e.deltaY / 100, minZoom),
            maxZoom,
          );

          const {
            left: left1,
            right: right1,
            top: top1,
            bottom: bottom1,
          } = getRect(aspectRatio, zoom.current);

          const width0 = right0 - left0;
          const height0 = top0 - bottom0;
          const width1 = right1 - left1;
          const height1 = top1 - bottom1;
          const dx = width1 - width0;
          const dy = height1 - height0;

          const xRatio = Math.max(0, Math.min(1, (e.point.x - left0) / width0));
          const yRatio = Math.max(0, Math.min(1, (top0 - e.point.y) / height0));

          groupRef.current.position.x += (xRatio - 0.5) * dx;
          groupRef.current.position.y -= (yRatio - 0.5) * dy;
        }}
        visible={false}
      >
        <planeBufferGeometry attach="geometry" args={[100, 100]} />
      </mesh>
      <group ref={groupRef}>
        <CanvasPlane
          texture={texture}
          position={[0, 0, 0]}
          size={[50, 50]}
          transparent={true}
          side={BackSide}
        />
        {planets.map(planet => (
          <Planet
            key={planet.id}
            {...planet.traits}
            position={[
              (planet.location[0] % 10) * 5 - 25,
              (planet.location[1] % 10) * 5 - 25,
              10,
            ]}
            scale={0.02}
            groupRef={el => {
              planetsRef.current[planet.id] = el;
            }}
            onPointerEnter={() => {
              setTargetPlanet(planet.id);
            }}
            onPointerLeave={() => {
              setTargetPlanet(null);
            }}
            onClick={() => {
              viewPlanet(planet.id);
            }}
            animate={targetPlanet === planet.id}
          />
        ))}
      </group>
      <TargetOutline
        selectedObjects={
          targetPlanet === null ? [] : [planetsRef.current[targetPlanet]]
        }
      />
    </>
  );
};
