import React from 'react';
import styled from 'styled-components';
import { Section } from '.';
import { POLYGONSCAN_URI } from '../config';
import { shortAddress } from '../utils';

type Props = {
  pirateEvents: {
    transactionHash: string;
    returnValues: {
      owner: string;
      pirate: string;
      planetId: string;
      success: boolean;
    };
  }[];
};

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  margin-bottom: 1rem;
`;

export default function Events({ pirateEvents }: Props) {
  return (
    <>
      <h2>PIRATE ATTEMPTS</h2>
      <Section style={{ padding: '1rem' }}>
        <Row>
          <div>Tx Hash</div>
          <div>Planet ID</div>
          <div>Status</div>
        </Row>
        {pirateEvents.map(event => (
          <div key={event.transactionHash}>
            <Row>
              <div>
                {' '}
                <a
                  href={`${POLYGONSCAN_URI}${event.transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {shortAddress(event.transactionHash)}
                </a>
              </div>
              <div>{event.returnValues.planetId}</div>

              <div
                style={{
                  color: event.returnValues.success ? 'lime' : 'darkred',
                }}
              >
                {event.returnValues.success ? 'Succeeded' : 'Failed'}
              </div>
            </Row>
          </div>
        ))}
      </Section>
    </>
  );
}
