import React, { FC, useState, useContext } from 'react';
import { Button, Link, LoadingIndicator, Modal, PlanetList } from '.';
import { Web3Context } from '../contexts/Web3Context';
import { dataStore } from '../dataStore';
import { PlanetData } from '../dataStore/types';
import { useAsyncData } from '../utils/useAsyncData';
import { TextField } from './';

type EnableShipModalProps = {
  shipId: string;
  onSubmit: (shipId: string, planetId: string) => void;
  close: () => void;
};

export const EnableShipModal: FC<EnableShipModalProps> = ({
  shipId,
  onSubmit,
  close,
}) => {
  // check for enabled planets
  // if no enabled planets the show message to first enable planet

  const { address } = useContext(Web3Context);
  const [planetId, updatePlanetId] = useState('');
  const [targetPlanet, setTargetPlanet] = useState<PlanetData | null>(null);

  const [loading, error, data] = useAsyncData(
    () => Promise.all([dataStore.enabledPlanets(address)]),
    [address],
  );

  if (loading || error || data === null) {
    return (
      <Modal close={close} title={`Choose a planet to land on`}>
        <LoadingIndicator
          style={{
            maxHeight: '300px',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
          error={error}
        />
      </Modal>
    );
  }

  const [enabledPlanets] = data;

  return (
    <Modal close={close} title={`Choose a planet to land on`}>
      <div style={{ marginBottom: '40px' }}>
        <h2 style={{ marginBottom: '10px' }}>Enter a planet ID</h2>
        <form
          onSubmit={async e => {
            e.preventDefault();
            await onSubmit(shipId, planetId);
            close();
          }}
        >
          <TextField
            onChange={(val: string) => updatePlanetId(val)}
            value={planetId}
          />
          <div style={{ marginTop: '10px' }}>
            <Button type="submit">Continue</Button>
          </div>
        </form>
      </div>
      <div>- or -</div>

      <h2>Choose one of your planets</h2>
      {enabledPlanets.length === 0 ? (
        <span>
          You do not have any planets enabled for gameplay.
          <br />
          Enable planets from your{' '}
          <Link href={`/wallets/${address}`}>wallet page</Link>.
        </span>
      ) : (
        <>
          <PlanetList
            planets={enabledPlanets}
            itemsPerRow={5}
            targetPlanet={targetPlanet}
            onHoverPlanet={id => {
              setTargetPlanet(id);
            }}
            onClickPlanet={async planet => {
              await onSubmit(shipId, planet.id);
              close();
            }}
            outline
          />
        </>
      )}
    </Modal>
  );
};
