import React, { FC, useContext, useState } from 'react';
import { dataStore } from '../../dataStore';
import { useAsyncData } from '../../utils/useAsyncData';
import {
  StatusBarPageLayout,
  PlanetList,
  ShipList,
  LoadingIndicator,
  Section,
} from '../../components';
import { PlanetData, ShipData } from '../../dataStore/types';
import Events from '../../components/Events';
import { TxModalContext } from '../../App';

export type AddressedWalletPageProps = {
  address: string;
};

export const AddressedWalletPage: FC<AddressedWalletPageProps> = ({
  address,
}) => {
  const { nonce } = useContext(TxModalContext);
  const [targetPlanet, setTargetPlanet] = useState<PlanetData | null>(null);
  const [targetShip, setTargetShip] = useState<ShipData | null>(null);
  const [targetWalletPlanet, setTargetWalletPlanet] =
    useState<PlanetData | null>(null);
  const [targetWalletShip, setTargetWalletShip] = useState<ShipData | null>(
    null,
  );

  const itemsPerRow = 5;
  const [loading, error, data] = useAsyncData(
    () =>
      Promise.all([
        dataStore.enabledPlanets(address),
        dataStore.enabledShips(address),
        dataStore.walletPlanets(address),
        dataStore.walletShips(address),
        dataStore.walletEL69Balance(address),
        dataStore.globalMiningPower(),
        dataStore.rewardPoolBalance(),
        dataStore.showWelcomeMessage(),
        dataStore.getPirateEventsForAddress(address),
      ]),
    [address, nonce],
  );

  if (loading || error || data === null) {
    return <LoadingIndicator error={error} />;
  }

  const [
    enabledPlanets,
    enabledShips,
    walletPlanets,
    walletShips,
    balance,
    globalMiningPower,
    rewardPoolBalance,
    showWelcomeMessage,
    pirateEvents,
  ] = data;

  return (
    <StatusBarPageLayout
      breadcrumbs={[
        ['Star map', '/starmap'],
        // [`Wallet ${shortAddress(address)}`, `/wallets/${address}`],
      ]}
      content={
        <div
          style={{ padding: '0 2rem 6rem', maxWidth: '1000px', margin: 'auto' }}
        >
          <h2>STATS</h2>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              gap: '0.33rem',
              marginBottom: '6rem',
            }}
          >
            <div>GLOBAL MINING POWER: {globalMiningPower}</div>
            <div>REWARD POOL BALANCE: {rewardPoolBalance}</div>
            <div>WALLET BALANCE: {balance} $EL69</div>
          </div>
          {showWelcomeMessage && (
            <>
              <Section style={{ padding: '20px' }}>
                <span>
                  Welcome to Invaders!
                  <br />
                  If this is you first time playing check out the{' '}
                  <a
                    href="https://invaders.gitbook.io/invaders/guides/quickstart"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Getting Started Guide
                  </a>
                </span>
              </Section>
            </>
          )}
          <h2>STAR SHIPS IN GAME</h2>
          <Section
            style={enabledShips.length === 0 ? { padding: '20px' } : void 0}
          >
            {enabledShips.length === 0 && (
              <span>
                No ships in game. Choose ship from wallet below to play.
              </span>
            )}
            {enabledShips.length > 0 && (
              <ShipList
                ships={enabledShips}
                itemsPerRow={itemsPerRow}
                targetShip={targetShip}
                onHoverShip={ship => setTargetShip(ship)}
                outline
              />
            )}
          </Section>
          <h2>PLANETS IN GAME</h2>
          <Section
            style={enabledPlanets.length === 0 ? { padding: '20px' } : void 0}
          >
            {enabledPlanets.length === 0 && (
              <span>
                No planets in game. Choose planet from wallet below to play.
              </span>
            )}
            {enabledPlanets.length > 0 && (
              <PlanetList
                planets={enabledPlanets}
                itemsPerRow={itemsPerRow}
                targetPlanet={targetPlanet}
                onHoverPlanet={planet => setTargetPlanet(planet)}
                outline
              />
            )}
          </Section>

          {pirateEvents && pirateEvents.length > 0 && (
            <Events pirateEvents={pirateEvents} />
          )}

          <h2>STAR SHIPS IN WALLET</h2>
          <Section
            style={walletShips.length === 0 ? { padding: '20px' } : void 0}
          >
            {walletShips.length === 0 && (
              <span>
                No ships in wallet. Buy a ship from the marketplace to play.
              </span>
            )}
            {walletShips.length > 0 && (
              <ShipList
                ships={walletShips}
                itemsPerRow={itemsPerRow}
                targetShip={targetWalletShip}
                onHoverShip={ship => setTargetWalletShip(ship)}
                outline
              />
            )}
          </Section>

          <h2>PLANETS IN WALLET</h2>
          <Section
            style={walletPlanets.length === 0 ? { padding: '20px' } : void 0}
          >
            {walletPlanets.length === 0 && (
              <span>
                No planets in wallet. Buy a planet from the marketplace to play.
              </span>
            )}
            {walletPlanets.length > 0 && (
              <PlanetList
                // @ts-ignore
                planets={walletPlanets}
                itemsPerRow={itemsPerRow}
                targetPlanet={targetWalletPlanet}
                onHoverPlanet={planet => setTargetWalletPlanet(planet)}
                outline
              />
            )}
          </Section>
        </div>
      }
    />
  );
};
