import React, { FC, ReactNode } from 'react';

export type CardProps = { children: ReactNode };

export const Card: FC<CardProps> = ({ children }) => (
  <div
    style={{
      padding: '1px 36px',
      fontSize: '36px',
      margin: '12px',
      maxWidth: '520px',
      position: 'relative',
      background: 'white',
      color: 'black',
    }}
  >
    {children}
  </div>
);
