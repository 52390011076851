import { CanvasTexture, UVMapping, RepeatWrapping, NearestFilter } from 'three';
import { GenerativeTexture } from '../types';
import { repeat, rand, randColor, randBit, isDark } from '../utils';

export const life = (): GenerativeTexture => {
  const size = Math.floor(rand(11, 47));
  const count = size * size;
  const minLive = Math.floor(count / 8);
  const world = repeat(count, randBit);
  const neighborCount = new Array(count);
  const colors = repeat(3, () => randColor());

  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const arr = new Uint8ClampedArray(4 * count);

  const countNeighbors = () => {
    for (let y = 0; y < count; y += size) {
      let prevY = (y + count - size) % count;
      let nextY = (y + size) % count;
      for (let x = 0; x < size; x++) {
        let prevX = (x + size - 1) % size;
        let nextX = (x + 1) % size;
        neighborCount[y + x] =
          world[prevY + prevX] +
          world[prevY + x] +
          world[prevY + nextX] +
          world[y + prevX] +
          world[y + nextX] +
          world[nextY + prevX] +
          world[nextY + x] +
          world[nextY + nextX];
      }
    }
  };
  const stepWorld = () => {
    let total = 0;
    for (let i = 0; i < count; i++) {
      const nc = neighborCount[i];
      if (nc === 3 || (world[i] && nc === 2)) {
        world[i] = 1;
        total++;
      } else {
        world[i] = 0;
      }
    }
    for (let i = total; i < minLive; i++) {
      world[Math.floor(Math.random() * count)] = 1;
    }
    world[Math.floor(Math.random() * count)] = 1;
  };
  const draw = () => {
    for (let i = 0; i < count; i++) {
      const [r, g, b] = colors[world[i] ? 0 : neighborCount[i] ? 1 : 2];
      const pos = i * 4;
      arr[pos] = r;
      arr[pos + 1] = g;
      arr[pos + 2] = b;
      arr[pos + 3] = 255;
    }
    ctx.putImageData(new ImageData(arr, size), 0, 0);
  };

  countNeighbors();
  draw();

  const texture = new CanvasTexture(
    canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    NearestFilter,
    NearestFilter,
  );
  texture.repeat.x = rand(1, 5);
  texture.repeat.y = rand(1, 7);

  const frameTime = 1 / 10;
  let elapsed = 0;

  return {
    texture,
    canvas,
    step: delta => {
      elapsed += delta;
      if (elapsed > frameTime) {
        elapsed = elapsed % frameTime;

        stepWorld();
        countNeighbors();
        draw();
        texture.needsUpdate = true;
      }
    },
    dark: isDark(colors),
  };
};
