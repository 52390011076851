import styled from 'styled-components';

export const Button = styled.button`
  border: 2px solid lime;
  background: #2b2b2b;
  color: mediumpurple;
  font-family: monogram;
  font-size: 1em;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    background: black;
  }
`;
