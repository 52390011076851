import React, { FC, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { MoonTraits, Vector, GenerativeTexture } from '../types';
import { DoubleSide } from 'three';

export type MoonProps = {
  radius: number;
  segments: [number, number];
  rotation: Vector;
  texture: GenerativeTexture;
  orbitStart: number;
  orbitPeriod: number;
  orbitRadius: number;
  animate?: boolean;
};

export const Moon: FC<MoonProps> = ({
  radius,
  segments,
  texture,
  rotation,
  orbitRadius,
  orbitPeriod,
  orbitStart,
  animate,
}) => {
  const ref = useRef<any>();
  useFrame((state, delta) => {
    if (animate === false) return;
    const angle =
      (state.clock.elapsedTime / orbitPeriod) * 2 * Math.PI + orbitStart;
    ref.current.position.x = Math.cos(angle) * orbitRadius;
    ref.current.position.z = Math.sin(angle) * orbitRadius;
    texture.step(delta);
  });

  return (
    <mesh rotation={rotation} ref={ref}>
      <sphereGeometry attach="geometry" args={[radius, ...segments]} />
      <meshStandardMaterial map={texture.texture} attach="material" />
    </mesh>
  );
};

export type PlanetProps = {
  position: Vector;
  radius: number;
  segments?: [number, number];
  rotation?: Vector;
  texture: GenerativeTexture;
  ringTexture?: GenerativeTexture;
  ringColor?: string;
  ringRadii?: [number, number];
  ringSegments?: number;
  groupRef?: (el: any) => void;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
  onClick?: () => void;
  moons: MoonTraits[];
  scale?: number;
  animate?: boolean;
};

export const Planet: FC<PlanetProps> = ({
  texture,
  position,
  radius,
  segments = [6, 6],
  rotation = [Math.PI / 2.4, 0, 0],
  ringTexture,
  ringColor,
  ringRadii = [1.2 * radius, 1.5 * radius],
  ringSegments = 16,
  groupRef,
  onPointerEnter,
  onPointerLeave,
  onClick,
  moons,
  scale,
  animate,
}) => {
  const planetRef = useRef<any>();
  const ringRef = useRef<any>();
  useFrame((_, delta) => {
    if (animate === false) return;
    texture.step(delta);
    ringTexture?.step(delta);
    if (ringRef.current) {
      ringRef.current.rotation.z -= delta / 9;
    }
  });

  return (
    <group
      position={position}
      rotation={rotation}
      scale={scale}
      ref={groupRef}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onClick={onClick}
    >
      <mesh ref={planetRef}>
        <sphereGeometry attach="geometry" args={[radius, ...segments]} />
        <meshStandardMaterial map={texture.texture} attach="material" />
      </mesh>
      {(ringColor || ringTexture) && (
        <mesh rotation={[-Math.PI / 2, 0, 0]} ref={ringRef}>
          <ringGeometry attach="geometry" args={[...ringRadii, ringSegments]} />
          <meshStandardMaterial
            side={DoubleSide}
            color={ringColor}
            map={ringTexture?.texture}
            attach="material"
          />
        </mesh>
      )}
      {moons.map((moon, i) => (
        <Moon key={i} {...moon} animate={animate} />
      ))}
    </group>
  );
};
