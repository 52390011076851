import { CellValue, ShipTraits } from '../types';
import {
  rand,
  randBit,
  randColorString,
  randInt,
  countNeighbors,
} from '../utils';

const nextGeneration = (matrix: number[][]) => {
  for (let row = 0; row < matrix.length; row++) {
    for (let col = 0; col < matrix[row].length; col++) {
      const n = countNeighbors(matrix, col, row);
      const cell = matrix[row][col];
      if (cell) {
        let next = 0;
        if (n < 2) {
          next = 0;
        } else if (n === 2 || n === 3) {
          next = 1;
        } else if (n > 3) {
          next = 0;
        }

        matrix[row][col] = next;
      } else if (n === 3) {
        matrix[row][col] = 1;
      }
    }
  }
};

const generateMatrix = (l: number, w: number): CellValue[][] => {
  const bitMatrix: number[][] = [];

  for (let i = 0; i < l; i++) {
    bitMatrix.push(new Array(w).fill('x').map(() => randBit()));
  }

  nextGeneration(bitMatrix);
  nextGeneration(bitMatrix);
  nextGeneration(bitMatrix);

  return bitMatrix.map((row, y) =>
    row.map((v, x) =>
      v
        ? CellValue.ON
        : countNeighbors(bitMatrix, x, y) > 0
        ? CellValue.BORDER
        : CellValue.OFF,
    ),
  );
};

export const randShip = (): ShipTraits => {
  const dimensions = {
    blasters: {
      l: randInt(12, 15),
      w: randInt(3, 5),
    },
    body: {
      l: randInt(8, 11),
      w: randInt(8, 10),
    },
  };
  const base = randInt(0, 200);
  const c1 = `rgb(${base},${base},${base})`;
  const c2 = randColorString();
  const c3 = randColorString();
  const c4 = randColorString();

  return {
    dimensions,
    shipLayers: [
      {
        matrix: generateMatrix(dimensions.body.l, dimensions.body.w),
        fillColor: c1,
        borderColor: c2,
        yOffset: 2,
      },
      {
        matrix: generateMatrix(dimensions.body.l, dimensions.body.w),
        fillColor: c1,
        borderColor: c3,
        yOffset: 2,
      },
    ],
    blasterLayers: [
      {
        matrix: generateMatrix(dimensions.blasters.l, dimensions.blasters.w),
        fillColor: c1,
        borderColor: c2,
      },
      {
        matrix: generateMatrix(dimensions.blasters.l, dimensions.blasters.w),
        fillColor: c1,
        borderColor: c3,
      },
      {
        matrix: generateMatrix(dimensions.blasters.l, dimensions.blasters.w),
        fillColor: null,
        borderColor: c4,
      },
    ],
    size: [
      dimensions.blasters.w * 2 + dimensions.body.w,
      dimensions.blasters.l,
      3,
    ],
    maxVelocity: rand(2, 5),
    accelleration: rand(25, 50),
    fireRate: rand(0.25, 0.4),
    bulletVelocity: rand(50, 200, 2),
  };
};
