import { CanvasTexture, UVMapping, RepeatWrapping, NearestFilter } from 'three';
import { GenerativeTexture } from '../types';
import { rand, randInt, repeat } from '../utils';

// TODO
export const hyperspace = (): GenerativeTexture => {
  const width = Math.floor(rand(60, 120));
  const height = Math.floor(rand(60, 120));
  const minColor = 0;
  const speed = rand(0.6, 3);
  const numStars = Math.floor(rand(23, 113));
  const spawnRadius = rand(width / 4);

  const initStar = () => {
    var vx = (rand() > 0.5 ? -1 : 1) * rand(0.1, 1);
    var vy = (rand() > 0.5 ? -1 : 1) * rand(0.1, 1);
    return {
      vx,
      vy,
      x: width / 2 + vx * spawnRadius,
      y: height / 2 + vy * spawnRadius,
      r: randInt(minColor, 256),
      g: randInt(minColor, 256),
      b: randInt(minColor, 256),
    };
  };
  const stars = repeat(numStars, initStar);

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  const draw = () => {
    const arr = new Uint8ClampedArray(4 * width * height);
    for (let i = 3; i < arr.length; i += 4) {
      arr[i] = 255;
    }
    for (let i = 0; i < stars.length; i++) {
      const { x, y, vx, vy, r, g, b } = stars[i];
      const idx = (Math.round(y) * width + Math.round(x)) * 4;
      arr[idx] = r;
      arr[idx + 1] = g;
      arr[idx + 2] = b;

      const idx2 =
        (Math.round(y + vy * 2) * width + Math.round(x + vx * 2)) * 4;
      arr[idx2] = r;
      arr[idx2 + 1] = g;
      arr[idx2 + 2] = b;
    }
    ctx.putImageData(new ImageData(arr, width), 0, 0);
  };
  draw();

  const texture = new CanvasTexture(
    canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    NearestFilter,
    NearestFilter,
  );

  const frameTime = 1 / 30;
  let elapsed = 0;

  return {
    texture,
    canvas,
    step: delta => {
      elapsed += delta;
      if (elapsed > frameTime) {
        elapsed = elapsed % frameTime;

        for (let i = 0; i < numStars; i++) {
          const star = stars[i];
          star.y += star.vy * speed;
          star.x += star.vx * speed;
          if (star.x < 0 || star.x > width || star.y < 0 || star.y > height) {
            stars[i] = initStar();
          }
        }
        draw();
        texture.needsUpdate = true;
      }
    },
    dark: true,
  };
};
