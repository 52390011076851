import React, { FC, useMemo } from 'react';
import BN from 'bignumber.js';

export type TraitBarProps = {
  label: string;
  value: number;
  max?: number;
};

export const TraitBar: FC<TraitBarProps> = ({ label, value, max = 4000 }) => {
  const v = useMemo(() => new BN(value).div(max).toNumber(), [value]);
  return (
    <div>
      <div
        style={{
          height: '0.66rem',
          position: 'relative',
          background: '#009',
          margin: '0 0 0.125rem',
          fontSize: '1.5em',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: `${v * 100}%`,
            background: '#0c3',
          }}
        />
      </div>
      {`${label} ${value}/${max}`}
    </div>
  );
};
