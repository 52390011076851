import React, { FC } from 'react';

export const Lights: FC = () => {
  return (
    <>
      <ambientLight intensity={0.2} />
      <directionalLight position={[0, -400, 1200]} intensity={0.8} />
    </>
  );
};
