import { CanvasTexture, UVMapping, RepeatWrapping, NearestFilter } from 'three';
import { GenerativeTexture } from '../types';
import { repeat, rand, randColor, randInt, randBit, isDark } from '../utils';

export const rule90 = (): GenerativeTexture => {
  const size = Math.floor(rand(29, 71));
  const minLive = Math.ceil(size / 10);
  const color = randColor();
  const baseColor = randColor();

  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const arr = new Uint8ClampedArray(4 * size);

  let frame = 0;
  const frameDivisor = randInt(3, 11);

  let pattern = repeat(size, randBit);
  let position = 0;

  const draw = () => {
    const nextPattern = new Array(size);
    let count = 0;
    for (let i = 0; i < size; i++) {
      if (pattern[(i + size - 1) % size] !== pattern[(i + 1) % size]) {
        nextPattern[i] = true;
        count++;
      }
    }
    for (let i = count; i < minLive; i++) {
      nextPattern[Math.floor(Math.random() * count)] = 1;
    }
    for (let i = 0; i < size; i++) {
      let [r, g, b] = nextPattern[i] ? color : baseColor;
      const pos = i * 4;
      arr[pos] = r;
      arr[pos + 1] = g;
      arr[pos + 2] = b;
      arr[pos + 3] = 255;
    }
    const current = ctx.getImageData(0, 1, size, size - 1);
    ctx.putImageData(current, 0, 0);
    ctx.putImageData(new ImageData(arr, size), 0, size - 1);

    pattern = nextPattern;
    position = (position + 1) % size;
  };
  repeat(size, draw);

  const texture = new CanvasTexture(
    canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    NearestFilter,
    NearestFilter,
  );
  texture.repeat.x = rand(1, 5);
  texture.repeat.y = rand(1, 1);
  texture.rotation = randBit() ? Math.PI : 0;

  return {
    texture,
    canvas,
    step: () => {
      frame += 1;
      if (frame % frameDivisor === 0) {
        draw();
        texture.needsUpdate = true;
      }
    },
    dark: isDark([color, baseColor]),
  };
};
