import { CanvasTexture, UVMapping, RepeatWrapping, NearestFilter } from 'three';
import { GenerativeTexture } from '../types';
import { rand, randInt, randColor, isDark } from '../utils';

export const zebra = (): GenerativeTexture => {
  const size = Math.floor(rand(5, 31));
  const pattern = new Array(size).fill(0).map(() => randInt(0, 2));
  const offsets = new Array(size).fill(0).map(() => randInt(0, 2));
  const c1 = randColor();
  const c2: [number, number, number] = rand() > 0.5 ? randColor() : [0, 0, 0];

  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const arr = new Uint8ClampedArray(4 * size * size);

  let frame = 0;
  const yStep = size * 4;
  const draw = () => {
    for (let y = 0, yPos = 0; y < arr.length; y += 1, yPos += yStep) {
      for (
        let x = 0, pos = yPos, max = yPos + yStep;
        pos < max;
        x += 1, pos += 4
      ) {
        const value = pattern[(x + offsets[y] + Math.floor(frame / 10)) % size];
        const color = value > 0 ? c1 : c2;
        arr[pos] = color[0];
        arr[pos + 1] = color[1];
        arr[pos + 2] = color[2];
        arr[pos + 3] = 255;
      }
    }
    ctx.putImageData(new ImageData(arr, size), 0, 0);
  };
  draw();

  const texture = new CanvasTexture(
    canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    NearestFilter,
    NearestFilter,
  );
  texture.repeat.x = rand(1, 5);
  texture.repeat.y = rand(1, 7);
  texture.rotation = (randInt(0, 4) * Math.PI) / 2;

  const frameTime = 1 / randInt(20, 5);
  let elapsed = 0;

  return {
    texture,
    canvas,
    step: delta => {
      elapsed += delta;
      if (elapsed > frameTime) {
        elapsed = elapsed % frameTime;
        for (let i = 0; i < size; i++) {
          const prev = offsets[(i + size - 1) % size];
          const current = offsets[i];
          const next = offsets[(i + 1) % size];
          if (prev === current) {
            if (next < current) offsets[i] -= 1;
            else offsets[i] += 1;
          } else if (next === current) {
            if (prev < current) offsets[i] -= 1;
            else offsets[i] += 1;
          }
        }
        draw();
        texture.needsUpdate = true;
      }
    },
    dark: isDark([c1, c2]),
  };
};
