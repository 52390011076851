import React, { FC, ReactNode, useContext } from 'react';
import styled from 'styled-components';
import { Link } from './Link';
import { shortAddress } from '../utils';
import { Web3Context } from '../contexts/Web3Context';
import { dataStore } from '../dataStore';
import { useAsyncData } from '../utils/useAsyncData';
import { EXCHANGE_URL } from '../config';
import { TxModalContext } from '../App';

const Container = styled.div`
  height: 100vh;
  font-size: 2em;
`;
const StatusBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding: 0 2rem;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 4rem);
  overflow-y: auto;
`;
const RightLink = styled.div`
  @media (max-width: 35rem) {
    display: none;
  }
`;
const NavLinks = styled.nav`
  display: flex;
  gap: 1rem;
  @media (max-width: 60rem) {
    display: none;
  }
`;

export type StatusBarPageLayoutProps = {
  content: ReactNode;
  breadcrumbs: [string, string | null][];
};

export const StatusBarPageLayout: FC<StatusBarPageLayoutProps> = ({
  content,
  breadcrumbs,
}) => {
  const { address } = useContext(Web3Context);
  const viewerAddress = dataStore.viewerAddress();
  const { nonce } = useContext(TxModalContext);

  const [, , data] = useAsyncData(() => {
    return Promise.all(
      viewerAddress ? [dataStore.walletEL69Balance(viewerAddress)] : [],
    );
  }, [viewerAddress, nonce]);

  const [el69Balance] = data ?? [];
  return (
    <Container>
      <StatusBar>
        <div>
          {breadcrumbs.map(([text, href], i) => (
            <span key={i}>
              {i > 0 ? ' | ' : ''}
              {href === null ? text : <Link href={href}>{text}</Link>}
            </span>
          ))}
        </div>
        {viewerAddress && (
          <NavLinks>
            <a
              href="https://invaders.gitbook.io/invaders"
              target="_blank"
              rel="noreferrer"
            >
              Guides
            </a>

            <a
              href="https://opensea.io/collection/star-ships-1"
              target="_blank"
              rel="noreferrer"
            >
              Get Ships
            </a>
            <Link href="/mint-planet">Get Planets</Link>
            <a href={EXCHANGE_URL}>Get EL69</a>
          </NavLinks>
        )}
        <RightLink>
          {el69Balance && `${el69Balance} EL69 | `}
          <Link href={`/wallets/${address}`}>{shortAddress(address)}</Link>
        </RightLink>
      </StatusBar>
      <Content>{content}</Content>
    </Container>
  );
};
