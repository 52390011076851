import { CanvasTexture, UVMapping, RepeatWrapping, NearestFilter } from 'three';
import { GenerativeTexture } from '../types';

interface SectorsTexture extends GenerativeTexture {
  setTarget(target: number | null): void;
}

export const sectors = (): SectorsTexture => {
  const canvas = document.createElement('canvas');
  const size = 201;
  const cellSize = (size - 1) / 10;
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const arr = new Uint8ClampedArray(4 * size * size);
  let target: number | null = null;

  const yStep = size * 4;
  const draw = () => {
    for (let y = 0, yPos = 0; y < arr.length; y += 1, yPos += yStep) {
      for (
        let x = 0, pos = yPos, max = yPos + yStep;
        pos < max;
        x += 1, pos += 4
      ) {
        arr[pos] = 255;
        arr[pos + 1] = 255;
        arr[pos + 2] = 255;
        arr[pos + 3] = x % 20 === 0 || y % 20 === 0 ? 255 : 0;
      }
    }

    ctx.putImageData(new ImageData(arr, size), 0, 0);
  };
  draw();

  const texture = new CanvasTexture(
    canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    NearestFilter,
    NearestFilter,
  );

  const fillCell = (sector: number, opacity: number) => {
    const minX = ((sector - 1) % 10) * cellSize + 1;
    const maxX = minX + cellSize - 1;
    const minY = Math.floor((sector - 1) / 10) * cellSize + 1;
    const maxY = minY + cellSize - 1;
    for (let x = minX; x < maxX; x++) {
      for (let y = minY; y < maxY; y++) {
        const pos = (y * size + x) * 4;
        arr[pos + 3] = opacity;
      }
    }
  };

  return {
    texture,
    canvas,
    step: () => {},
    setTarget: (nextTarget: number | null) => {
      // clear previous active square
      if (target !== null) {
        fillCell(target, 0);
      }
      // fill next square
      if (nextTarget !== null) {
        fillCell(nextTarget, 255);
      }
      target = nextTarget;
      ctx.putImageData(new ImageData(arr, size), 0, 0);
      texture.needsUpdate = true;
    },
    dark: false,
  };
};
