import React, { useState } from 'react';
import { Web3Context } from '../contexts/Web3Context';
import { web3Util } from '../utils/web3Utils';

export default function Web3Provider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [address, updateAddress] = useState<string>('');
  web3Util.on('accountsUpdated', ([connectedWallet]) => {
    updateAddress(connectedWallet);
  });

  React.useEffect(() => {
    try {
      if (window.localStorage?.getItem(web3Util.LS_KEY)) {
        web3Util.enable();
      }
    } catch {}
  }, []);
  return (
    <Web3Context.Provider
      value={{
        address,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
}
