import { CanvasTexture, UVMapping, RepeatWrapping, NearestFilter } from 'three';
import { GenerativeTexture } from '../types';

export const sector = (): GenerativeTexture => {
  const canvas = document.createElement('canvas');
  const size = 201;
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const arr = new Uint8ClampedArray(4 * size * size);

  const yStep = size * 4;
  const draw = () => {
    const mark = (pos: number) => {
      arr[pos] = 255;
      arr[pos + 1] = 255;
      arr[pos + 2] = 255;
      arr[pos + 3] = 255;
    };
    for (let i = 0; i < size; i++) {
      // top
      mark(i * 4);
      // bottom
      mark(size * yStep - i * 4);
      // left
      mark(i * yStep);
      // right
      mark((i + 1) * yStep - 4);
    }

    ctx.putImageData(new ImageData(arr, size), 0, 0);
  };
  draw();

  const texture = new CanvasTexture(
    canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    NearestFilter,
    NearestFilter,
  );

  return {
    texture,
    canvas,
    step: () => {},
    dark: false,
  };
};
