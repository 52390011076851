import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { LoadingIndicator } from './LoadingIndicator';

export type LoadingOverlayProps = { style?: React.CSSProperties };

export const LoadingOverlay: FC<LoadingOverlayProps> = ({ style }) => {
  return createPortal(
    <div style={{ position: 'fixed', ...style }}>
      <LoadingIndicator error={false} />
    </div>,
    document.body,
  );
};
