import { Vector } from '../types';

export const multiply = (
  [x1, y1, z1]: Vector,
  [x2, y2, z2]: Vector,
): Vector => [x1 * x2, y1 * y2, z1 * z2];

export const multiplyScalar = ([x, y, z]: Vector, n: number): Vector => [
  x * n,
  y * n,
  z * n,
];

export const divide = ([x1, y1, z1]: Vector, [x2, y2, z2]: Vector): Vector => [
  x1 / x2,
  y1 / y2,
  z1 / z2,
];

export const divideScalar = ([x, y, z]: Vector, n: number): Vector => [
  x / n,
  y / n,
  z / n,
];

export const add = ([x1, y1, z1]: Vector, [x2, y2, z2]: Vector): Vector => [
  x1 + x2,
  y1 + y2,
  z1 + z2,
];

export const addScalar = ([x1, y1, z1]: Vector, n: number): Vector => [
  x1 + n,
  y1 + n,
  z1 + n,
];

export const subtract = (
  [x1, y1, z1]: Vector,
  [x2, y2, z2]: Vector,
): Vector => [x1 - x2, y1 - y2, z1 - z2];

export const modulus = ([x1, y1, z1]: Vector, [x2, y2, z2]: Vector): Vector => [
  x1 % x2,
  y1 % y2,
  z1 % z2,
];

export const max = ([x1, y1, z1]: Vector, [x2, y2, z2]: Vector): Vector => [
  Math.max(x1, x2),
  Math.max(y1, y2),
  Math.max(z1, z2),
];

export const min = ([x1, y1, z1]: Vector, [x2, y2, z2]: Vector): Vector => [
  Math.min(x1, x2),
  Math.min(y1, y2),
  Math.min(z1, z2),
];

export const clamp = (
  [x, y, z]: Vector,
  [xMin, yMin, zMin]: Vector,
  [xMax, yMax, zMax]: Vector,
): Vector => [
  Math.min(Math.max(x, xMin), xMax),
  Math.min(Math.max(y, yMin), yMax),
  Math.min(Math.max(z, zMin), zMax),
];

export const magnitude = ([x, y, z]: Vector): number =>
  Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));

export const equals = ([x1, y1, z1]: Vector, [x2, y2, z2]: Vector): boolean =>
  x1 === x2 && y1 === y2 && z1 === z2;

export const contains = (
  [x, y, z]: Vector,
  [xMin, yMin, zMin]: Vector,
  [xMax, yMax, zMax]: Vector,
): boolean =>
  x >= xMin && x <= xMax && y >= yMin && y <= yMax && z >= zMin && z <= zMax;

export const floor = ([x, y, z]: Vector): Vector => [
  Math.floor(x),
  Math.floor(y),
  Math.floor(z),
];

export const volume = ([x, y, z]: Vector): number => x * y * z;

export const distance = (p1: Vector, p2: Vector): number => {
  const [x1, y1, z1] = p1;
  const [x2, y2, z2] = p2;

  const a = x2 - x1;
  const b = y2 - y1;
  const c = z2 - z1;

  return Math.sqrt(a * a + b * b + c * c);
};
