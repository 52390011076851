import React, { FC, useEffect, useMemo, useRef } from 'react';
import { CanvasPlane, Lights, Planet, TargetOutline } from '../../components';
import { useCamera } from '../../utils';
import { sectors } from '../../textures';
import { Vector } from '../../types';
import { Vector3 } from 'three';
import { PlanetData, ShipData } from '../../dataStore/types';

export type SectorsSceneProps = {
  planets: (PlanetData | null | undefined)[];
  ships: ShipData[];
  viewSector: (sector: number) => void;
  viewPlanet: (id: number) => void;
  viewShip: (id: number) => void;
  targetPlanet: string | null;
  setTargetPlanet: (id: string | null) => void;
  targetSector: number | null;
  setTargetSector: (id: number | null) => void;
};

const fov = 60;
const cameraPosition: Vector = [50, 24, 88];
const cameraTarget: [number, number, number] = [50, 43, 0];

const getSector = (point: Vector3): number => {
  const x = Math.floor(point.x / 10);
  const y = 9 - Math.floor(point.y / 10);
  return y * 10 + x + 1;
};

export const SectorsScene: FC<SectorsSceneProps> = ({
  planets,
  viewSector,
  viewPlanet,
  targetPlanet,
  setTargetPlanet,
  targetSector,
  setTargetSector,
}) => {
  useCamera(cameraPosition, cameraTarget, fov);
  const texture = useMemo(sectors, []);
  const planetsRef = useRef<{ [id: string]: any }>({});

  useEffect(() => {
    texture.setTarget(targetSector);
  }, [targetSector]);

  return (
    <>
      <Lights />
      <CanvasPlane
        texture={texture}
        position={[50, 50, 0]}
        size={[100, 100]}
        onClick={e => {
          const sector = getSector(e.point);
          viewSector(sector);
        }}
        transparent
        onPointerMove={e => {
          const sector = getSector(e.point);
          if (targetSector !== sector && targetPlanet === null) {
            setTargetSector(sector);
          }
        }}
        onPointerLeave={() => {
          setTargetSector(null);
        }}
      />
      {planets.map(planet => (
        <>
          {planet && (
            <Planet
              key={planet.id}
              {...planet.traits}
              position={[planet.location[0], 100 - planet.location[1], 1]}
              scale={0.05}
              groupRef={el => {
                planetsRef.current[planet.id] = el;
              }}
              onPointerEnter={() => {
                setTargetPlanet(planet.id);
                setTargetSector(null);
              }}
              onPointerLeave={() => {
                setTargetPlanet(null);
                setTargetSector(null);
              }}
              onClick={() => {
                viewPlanet(parseInt(planet.id));
              }}
            />
          )}
        </>
      ))}
      <TargetOutline
        selectedObjects={
          targetPlanet === null ? [] : [planetsRef.current[targetPlanet]]
        }
      />
    </>
  );
};
