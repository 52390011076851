import React, { FC } from 'react';

export const TermsPage: FC = () => {
  return (
    <div
      style={{
        margin: '4em auto',
        maxWidth: 500,
        fontSize: '2em',
      }}
    >
      <h1>Terms</h1>
      <p>
        INVADERS! are collections of digital artworks (NFTs) running on the
        Ethereum and Polygon networks. This website is only an interface
        allowing participants to exchange digital collectibles. Users are
        entirely responsible for the safety and management of their own private
        Ethereum wallets and validating all transactions and contracts generated
        by this website before approval. Furthermore, as the INVADERS! contracts
        runs on the Ethereum and Polygon networks, there is no ability to undo,
        reverse, or restore any transactions.
      </p>

      <p>
        This website and its connected services are “as is” and “as available,”
        without warranty of any kind. Without limiting this, we expressly
        disclaim all warranties, whether expressed, implied or statutory,
        regarding the website. Using applications on the blockchain is
        associated with a high degree of technological and/or other risks.
      </p>

      <p>
        The NFT-Tokens are non-fungible tokens representing ownership of a
        digital artwork only. Accordingly, no information on this website (or
        any other documents mentioned therein) is or may be considered to be
        advice or an invitation to enter into an agreement for any investment
        purpose. Further, as NFT-Token represent artwork, nothing on this
        websites qualifies or is intended to be an offering of securities in any
        jurisdiction nor does it constitute an offer or an invitation to
        purchase shares, securities or other financial product.
      </p>

      <p>
        Due to the artistic nature of the project, neither this document nor the
        NFT-Tokens have been registered with or approved by any regulator in any
        jurisdiction. It remains in your responsibility to assure that the
        purchase of the NFT-Tokens and the associated art is in compliance with
        laws and regulations in your jurisdiction.
      </p>

      <p>
        We undertake no obligation to publicly update or revise any information
        or opinions published on the website. We reserve the right to amend the
        information at any time without prior notice.
      </p>

      <p>
        By using this website you are accepting sole responsibility for any an
        all transactions involving INVADERS! digital collectibles.
      </p>
      <p>
        <a href="/">home</a>
      </p>
    </div>
  );
};
