import React, { createContext, FC, useEffect, useState } from 'react';
import { Router } from './components';
import {
  HomePage,
  TermsPage,
  NotFoundPage,
  PlanetPage,
  ShipPage,
  StarMapPage,
  WalletPage,
  SectorPage,
} from './pages';
import { extend } from '@react-three/fiber';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import Web3Provider from './components/Web3Provider';
import { MarketplacePage } from './pages/MarketplacePage';
import { PowerupsPage } from './pages/PowerupsPage';
import { useContext } from 'react';
import { Web3Context } from './contexts/Web3Context';
import { web3Util } from './utils/web3Utils';
import TxModal from './components/TxModal';
import { dataStore } from './dataStore';
import { Button } from './components/Button';
import { TextureSection } from './pages/_HomePage/TextureSection';
import { randTexture } from './textures';
import { Card } from './pages/_HomePage/Card';
import MintPlanet from './pages/MintPlanet';

extend({ EffectComposer, RenderPass, OutlinePass });

export type AppProps = any;

export const TxModalContext = createContext<{ close?: any; nonce: number }>({
  nonce: 0,
});

const Web3Check = () => {
  const { address } = useContext(Web3Context);
  const [walletRequired, updateWalletRequired] = useState(false);
  const [pendingTransactions, setPendingTransactions] = useState<string[]>([]);
  const [transactionModalActive, setTransactionModalActive] = useState(false);
  const [nonce, updateNonce] = useState(0);
  const [changeNetwork, updateChangeNetwork] = useState(false);
  const [networkCorrect, updateNetworkCorrect] = useState(false);

  const enable = async () => {
    if (web3Util.hasProvider) {
      try {
        await web3Util.enable();
      } catch {
        // no op
      }
    } else {
      updateWalletRequired(true);
    }
  };

  useEffect(() => {
    web3Util.on('enabled', () => {
      const yourNetworkId = 137;
      web3Util.web3?.eth.net
        .getId()
        .then(networkId => {
          if (networkId != yourNetworkId) {
            updateChangeNetwork(true);
            // MetaMask network is wrong
          } else {
            updateNetworkCorrect(true);
          }
        })
        .catch(() => {
          // unable to retrieve network id
        });

      web3Util.pollTx?.on('completed', () => {
        updateNonce(n => n + 1);
      });
    });
  }, []);

  useEffect(() => {
    dataStore.onTransactionStatusChange(hashes => {
      setPendingTransactions(hashes);
      setTransactionModalActive(true);
    });
    return () => dataStore.offTransactionStatusChange(setPendingTransactions);
  });

  if (changeNetwork || !networkCorrect) {
    return (
      <TextureSection texture={randTexture} id="">
        <Card>
          <p>To play Invaders! you must be on the Polygon network.</p>

          <Button
            style={{ marginBottom: '50px' }}
            onClick={() => {
              // @ts-ignore
              window.ethereum
                .request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: web3Util.web3?.utils.toHex('137') }],
                })
                .then(() => {
                  updateNetworkCorrect(true);
                })
                .catch((e: any) => {
                  if (e.code === 4902) {
                    console.log('network is not available, add it');
                    // @ts-ignore
                    window.ethereum
                      .request({
                        method: 'wallet_addEthereumChain',
                        params: [
                          {
                            chainId: web3Util.web3?.utils.toHex('137'),
                            chainName: 'Polygon Mainnet',
                            nativeCurrency: {
                              name: 'MATIC',
                              symbol: 'MATIC',
                              decimals: 18,
                            },
                            rpcUrls: ['https://polygon-rpc.com'],
                            blockExplorerUrls: ['https://polygonscan.com'],
                          },
                        ],
                      })
                      .then(() => {
                        updateNetworkCorrect(true);
                      })
                      .catch(() => console.log('could not add network'));
                  } else {
                    console.log('could not set network');
                  }
                });
            }}
          >
            Connect to Polygon
          </Button>
        </Card>
      </TextureSection>
    );
  }

  if (!address) {
    return (
      <TextureSection texture={randTexture} id="">
        <Card>
          {walletRequired ? (
            <>
              <p>
                You will need to download Metamask in order to play Invaders!
              </p>
              <p>
                Go to <a href="https://metamask.io">metamask.io</a> to download.
              </p>
            </>
          ) : (
            <>
              <p>Please to connect your wallet to interact with Invaders!</p>
              <p>
                <Button onClick={enable}>Connect wallet</Button>
              </p>
            </>
          )}
        </Card>
      </TextureSection>
    );
  } else {
    return (
      <>
        <TxModalContext.Provider
          value={{ close: () => setTransactionModalActive(false), nonce }}
        >
          <Router
            routes={[
              [/^\/$/, HomePage],
              [/^\/terms$/, TermsPage],
              [/^\/ships\/[0-9]+$/, ShipPage],
              [/^\/planets\/[0-9]+$/, PlanetPage],
              [/^\/starmap$/, StarMapPage],
              [/^\/starmap\/sectors\/[0-9]{1,4}$/, SectorPage],
              [/^\/wallets\/0x[0-9a-zA-Z]{40}$/, WalletPage],
              [/^\/marketplace$/, MarketplacePage],
              [/^\/powerups$/, PowerupsPage],
              [/^\/mint-planet$/, MintPlanet],
            ]}
            NotFound={() => <NotFoundPage />}
          />
        </TxModalContext.Provider>
        {transactionModalActive && (
          <TxModal
            transactions={pendingTransactions}
            close={() => setTransactionModalActive(false)}
          />
        )}
      </>
    );
  }
};

export const App: FC<AppProps> = () => (
  <Web3Provider>
    <Web3Check />
  </Web3Provider>
);
