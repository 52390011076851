import React, { FC, useContext, useEffect, useState } from 'react';
import { Scene } from './Scene';
import {
  EntityPageLayout,
  Button,
  TraitBar,
  LoadingIndicator,
  ApproveTransferModal,
  ShipList,
} from '../../components';
import { shortAddress } from '../../utils';
import { dataStore } from '../../dataStore';
import { useAsyncData } from '../../utils/useAsyncData';
import { ShipData, ShipStatus } from '../../dataStore/types';
import { MineModal } from './MineModal';
import { PirateModal } from './PirateModal';
import { TravelModal } from './TravelModal';
import { Web3Context } from '../../contexts/Web3Context';
import { web3Util } from '../../utils/web3Utils';
import { TxModalContext } from '../../App';

export type PlanetPageProps = {
  path: string[];
};

export const PlanetPage: FC<PlanetPageProps> = ({ path: [, idString] }) => {
  const { address } = useContext(Web3Context);
  const { close, nonce } = useContext(TxModalContext);
  const [targetShip, setTargetShip] = useState<ShipData | null>(null);
  const id = idString;

  const [mineModalActive, setMineModalActive] = useState(false);
  const [pirateModalActive, setPirateModalActive] = useState(false);
  const [travelModalActive, setTravelModalActive] = useState(false);
  const [approveModalActive, setApproveModalActive] = useState(false);
  const [approveHash, updateApproveHash] = useState<string>('');

  const [loading, error, data] = useAsyncData(
    () => Promise.all([dataStore.planet(id), dataStore.planetShips(id)]),
    [id, nonce],
  );

  const [enabled, updateEnabled] = useState(data?.[0]?.enabledForGameplay);

  const enableForGamePlay = async () => {
    const hash = await dataStore.enablePlanetForGameplay(id);
    web3Util.pollTx?.once('completed', txHash => {
      if (txHash === hash) {
        close();
        updateEnabled(true);
      }
    });
  };

  useEffect(() => {
    if (approveHash) {
      web3Util.pollTx?.once('completed', txHash => {
        if (txHash === approveHash) {
          close();
          enableForGamePlay();
        }
      });
    }
  }, [approveHash]);

  if (loading || error || data === null) {
    return <LoadingIndicator error={error} />;
  }

  const [planet, ships] = data;
  if (!planet) {
    return <>Planet does not exist</>;
  }
  const planetEnabledForGameplay = planet.enabledForGameplay || enabled;
  const viewer = dataStore.viewerAddress();
  const viewerIsOwner = address.toLowerCase() === planet.owner.toLowerCase();
  const viewerHasAvailableShips = ships.some(
    ship =>
      ship.owner.toLowerCase() === viewer?.toLowerCase() &&
      ship.status === ShipStatus.ACTIVE,
  );

  return (
    <>
      <EntityPageLayout
        breadcrumbs={[
          ['INVADERS!', '/starmap'],
          ['Planets', null],
          [`${planet.traits.name}`, `/planets/${id}`],
        ]}
        scene={<Scene planet={planet.traits} />}
        entityName={planet.traits.name}
        content={
          <>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                gap: '1rem',
              }}
            >
              <div>
                SECTOR:{' '}
                <a href={`/starmap/sectors/${planet.sector}`}>
                  {planet.sector}
                </a>
              </div>
              <div>
                OWNER:{' '}
                <a href={`/wallets/${planet.owner}`}>
                  {shortAddress(planet.owner)}
                </a>
              </div>
              {planet && !planetEnabledForGameplay && (
                <div style={{ color: 'darkred' }}>Inactive</div>
              )}
            </div>
            <div>
              <h3>SPACEPORT</h3>
              {ships && ships.length > 0 && (
                <div style={{ background: '#111' }}>
                  <ShipList
                    ships={ships}
                    itemsPerRow={3}
                    targetShip={targetShip}
                    onHoverShip={ship => setTargetShip(ship)}
                    outline
                  />
                </div>
              )}
              {ships && ships.length === 0 && 'No ships are on this planet.'}
            </div>
            <div>
              <h3>ACTIONS</h3>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '1rem',
                }}
              >
                {!planetEnabledForGameplay && !viewerIsOwner && (
                  <p>This planet is not enabled for gameplay.</p>
                )}
                {viewerIsOwner && !planetEnabledForGameplay && (
                  <Button
                    onClick={async () => {
                      try {
                        const isApproved = await dataStore.arePlanetsApproved();
                        if (isApproved) {
                          await enableForGamePlay();
                        } else {
                          setApproveModalActive(true);
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                  >
                    Enable for gameplay
                  </Button>
                )}
                {planetEnabledForGameplay && (
                  <>
                    {!viewerIsOwner && viewerHasAvailableShips && (
                      <Button
                        onClick={() => {
                          setPirateModalActive(true);
                        }}
                      >
                        <img
                          style={{ width: '16px', marginRight: '5px' }}
                          src="/pirate.png"
                        />
                        Pirate
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        setTravelModalActive(true);
                      }}
                    >
                      Send ship
                    </Button>
                    {viewerIsOwner && (
                      <Button
                        onClick={() => dataStore.disablePlanetForGameplay(id)}
                      >
                        Disable for gameplay
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div>
              <h3>STATS</h3>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '1rem',
                  fontSize: '1.5rem',
                }}
              >
                <TraitBar
                  label="Resource Depth"
                  value={parseInt(planet.stats.resourceDepth)}
                />
                <TraitBar
                  label="Biodiversity"
                  value={parseInt(planet.stats.biodiversity)}
                />
                <TraitBar
                  label="Albedo"
                  value={parseInt(planet.stats.albedo)}
                />
                <TraitBar
                  label="Geological instability"
                  value={parseInt(planet.stats.geologicalInstability)}
                />
                <TraitBar
                  label="Ground defenses"
                  value={parseInt(planet.stats.groundDefenses)}
                />
                <TraitBar
                  label="Space defenses"
                  value={parseInt(planet.stats.spaceDefenses)}
                />
              </div>
            </div>
          </>
        }
      />
      {mineModalActive && (
        <MineModal planet={planet} close={() => setMineModalActive(false)} />
      )}
      {pirateModalActive && (
        <PirateModal
          planet={planet}
          close={() => setPirateModalActive(false)}
        />
      )}
      {travelModalActive && (
        <TravelModal
          planet={planet}
          close={() => setTravelModalActive(false)}
        />
      )}
      {approveModalActive && (
        <ApproveTransferModal
          close={() => setApproveModalActive(false)}
          transferItem="Planet NFTs"
          onConfirm={async () => {
            const hash = await dataStore.approvePlanets();
            setApproveModalActive(false);
            updateApproveHash(hash);
          }}
        />
      )}
    </>
  );
};
