import BN from 'bignumber.js';

export function getMatrixFromId(id: string, length: string, width: string) {
  const l = parseInt(length);
  const w = parseInt(width);
  const bin = new BN(id).toString(2).padStart(l * w, '0');
  let out: number[][] = [];

  for (let i = 0; i < bin.length; i++) {
    const row = Math.floor(i / w);
    const normalizedIdx = i - row * w;
    if (i % w === 0) {
      out.push([]);
    }
    out[row][normalizedIdx] = parseInt(bin[i]);
  }
  return out;
}
