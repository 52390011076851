import { GenerativeTexture, PlanetTraits, ShipConfig } from '../types';

export interface PlanetStats {
  resourceDepth: string;
  biodiversity: string;
  albedo: string;
  geologicalInstability: string;
  groundDefenses: string;
  spaceDefenses: string;
}

export interface PlanetData {
  id: string;
  seed: number;
  owner: string;
  enabledForGameplay: boolean;
  location: [number, number];
  sector: number;
  traits: PlanetTraits;
  stats: PlanetStats;
  miningShipId?: string;
}

export enum ShipStatus {
  MINING = 'MINING',
  TRANSIT = 'TRANSIT',
  DISABLED = 'DISABLED',
  ACTIVE = 'ACTIVE',
}

export interface ShipStats {
  speed: string;
  fuelEfficiency: string;
  toolStrength: string;
  labCapacity: string;
  laserStrength: string;
  groundWeapons: string;
  spaceWeapons: string;
}

export interface ShipData {
  id: string;
  owner: string;
  enabledForGameplay: boolean;
  location?: [number, number] | null;
  currentPlanet: PlanetData | null;
  currentTime?: string | number | undefined;
  disabledUntil?: number;
  unclaimedRewards: string | null;
  status: ShipStatus | null;
  shipConfig: ShipConfig;
  stats: ShipStats;
  background: GenerativeTexture;
}

export interface ForceFieldData {
  id: number;
  owner: string;
  // TODO
}

export enum ListingType {
  SHIP = 'SHIP',
  PLANET = 'PLANET',
  FORCEFIELD = 'FORCEFIELD',
}

export interface ShipListing {
  price: number;
  type: ListingType.SHIP;
  item: ShipData;
}

export interface PlanetListing {
  price: number;
  type: ListingType.PLANET;
  item: PlanetData;
}

export interface ForcefieldListing {
  price: number;
  type: ListingType.FORCEFIELD;
  item: ForceFieldData;
}

export type Listing = ShipListing | PlanetListing | ForcefieldListing;

export interface DataStore {
  // listen to events
  onTransactionStatusChange(fn: (hashes: string[]) => void): void;
  offTransactionStatusChange(fn: (hashes: string[]) => void): void;

  // read viewer data
  viewerAddress(): string | null;

  // read global contract data
  globalMiningPower(): Promise<number>;
  rewardPoolBalance(): Promise<number>;
  getClaimableAmountByShipId(shipId: string): Promise<string>;
  getBonusAmount(address: string): Promise<string>;

  // read wallet data
  walletEL69Balance(address: string): Promise<string>;
  walletTotalMiningPower(address: string): Promise<number>;
  walletBonusBalance(address: string): Promise<number>;

  // ships and planets owned and enabled for gameplay
  enabledPlanets(walletAddress: string): Promise<PlanetData[]>;
  enabledShips(walletAddress: string): Promise<ShipData[]>;

  // ships and planets owned and not enabled for gameplay
  walletShips(walletAddress: string): Promise<ShipData[]>;
  walletPlanets(walletAddress: string): Promise<PlanetData[]>;

  // ships and planets by location
  sectorShips(sector: number): Promise<ShipData[]>;
  sectorPlanets(sector: number): Promise<PlanetData[]>;
  planetShips(planetId: string): Promise<ShipData[]>;

  // ships and planets by id
  planet(planetId: string): Promise<PlanetData | null>;
  ship(shipId: string): Promise<ShipData>;

  // action costs
  travelCost(
    shipId: string,
    planetId: string,
  ): Promise<{
    distance: any;
    costPerDistance: any;
    cost: string;
    displayCost: string;
  }>;
  pirateCost(shipId: string, planetId: string): Promise<number>;
  miningPower(shipId: string, planetId: string): Promise<number>;

  // approvals
  approvePlanets(): Promise<string>;
  approveShips(): Promise<string>;
  arePlanetsApproved(): Promise<boolean>;
  areShipsApproved(): Promise<boolean>;

  // mutate contract data
  claimBonus(): Promise<string>;
  enablePlanetForGameplay(planetId: string): Promise<string>;
  enableShipForGameplay(shipId: string, planetId: string): Promise<string>;
  disablePlanetForGameplay(planetId: string): Promise<string>;
  disableShipForGameplay(shipId: string): Promise<string>;
  mine(shipId: string): Promise<string>;
  withdrawMiningRewards(shipId: string, agressive: boolean): Promise<string>;
  travel(shipId: string, planetId: string): Promise<string>;
  pirate(
    shipId: string,
    planetId: string,
    quotedPrice: string,
    slippageTolerance: number,
  ): Promise<string>;

  // marketplace
  marketplaceShips(page: number, perPage: number): Promise<ShipListing[]>;
  marketplacePlanets(page: number, perPage: number): Promise<PlanetListing[]>;
  marketplaceForceFields(
    page: number,
    perPage: number,
  ): Promise<ForcefieldListing[]>;
  sell(type: ListingType, id: string, price: number): Promise<void>;
  buy(type: ListingType, id: string): Promise<void>;
  delist(type: ListingType, id: string): Promise<void>;

  // minting planets
  mintPrice(): Promise<number>;
  planetsAvailableToMint(): Promise<number>;
  approveEL69ForPlanetMint(): Promise<string>;
  mint(number: number): Promise<string>;

  // todo: global forcefield / powerups
}
