import { Canvas } from '@react-three/fiber';
import React, { FC, useContext, useMemo, useState } from 'react';
import { OrthographicCamera } from 'three';
import {
  HoverInfoCard,
  LoadingIndicator,
  StatusBarPageLayout,
} from '../../components';
import { RoutingContext } from '../../contexts';
import { dataStore } from '../../dataStore';
import { PlanetData } from '../../dataStore/types';
import { useAsyncData } from '../../utils/useAsyncData';
import { SectorScene } from './SectorScene';

export type SectorPageProps = {
  path: string[];
};

export const SectorPage: FC<SectorPageProps> = ({ path }) => {
  const sectorId = parseInt(path[2]);
  const { route } = useContext(RoutingContext);
  const [targetPlanet, setTargetPlanet] = useState<string | null>(null);
  const [loading, error, data] = useAsyncData(
    () =>
      Promise.all([
        dataStore.sectorPlanets(sectorId),
        dataStore.sectorShips(sectorId),
      ]),
    [sectorId],
  );

  const camera = useMemo(() => {
    const c = new OrthographicCamera(-30, 30, -30, 30, 1, 1000);
    c.position.set(0, 0, 100);
    c.lookAt(0, 0, 0);
    c.updateMatrix();
    return c;
  }, []);

  const targetPlanetData = useMemo<PlanetData | null>(() => {
    if (data) {
      const [planets] = data;
      return planets.find(p => p.id === targetPlanet) ?? null;
    }
    return null;
  }, [data, targetPlanet]);

  if (loading || error || data === null) {
    return <LoadingIndicator error={error} />;
  }

  const [planets, ships] = data;

  return (
    <StatusBarPageLayout
      breadcrumbs={[
        ['INVADERS!', '/starmap'],
        [`Sector ${sectorId}`, `/starmap/sectors/${sectorId}`],
      ]}
      content={
        <>
          <Canvas camera={camera}>
            {data && (
              <SectorScene
                sectorId={sectorId}
                planets={planets}
                ships={ships}
                viewPlanet={id => route(`/planets/${id}`)}
                targetPlanet={targetPlanet}
                setTargetPlanet={setTargetPlanet}
              />
            )}
          </Canvas>
          {targetPlanetData && (
            <HoverInfoCard>
              {targetPlanetData.traits.name}
              {!targetPlanetData.enabledForGameplay && (
                <p style={{ color: 'darkred', margin: 0 }}>
                  not enabled for gameplay
                </p>
              )}
            </HoverInfoCard>
          )}
        </>
      }
    />
  );
};
