import { CanvasTexture, UVMapping, RepeatWrapping, NearestFilter } from 'three';
import { GenerativeTexture } from '../types';
import { repeat, rand, randColor, randInt, randBit, isDark } from '../utils';

export const rule184 = (): GenerativeTexture => {
  const width = Math.floor(rand(29, 57));
  const height = 1 * width;
  const color = randColor();
  const baseColor = randColor();

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const arr = new Uint8ClampedArray(4 * width);

  let frame = 0;
  const frameDivisor = randInt(3, 11);

  let pattern = repeat(width, () => (rand() > 0.5 ? 0 : 1));

  const draw = () => {
    const nextPattern = new Array(width);
    let count = 0;
    for (let i = 0; i < width; i++) {
      if (
        (!pattern[i] && pattern[(i + width - 1) % width]) ||
        (pattern[i] && pattern[(i + 1) % width])
      ) {
        nextPattern[i] = true;
        count++;
      }
    }
    for (let i = 0; i < 3; i++) {
      nextPattern[Math.floor(Math.random() * count)] = Math.round(
        Math.random(),
      );
    }
    for (let i = 0; i < width; i++) {
      let [r, g, b] = nextPattern[i] ? color : baseColor;
      const pos = i * 4;
      arr[pos] = r;
      arr[pos + 1] = g;
      arr[pos + 2] = b;
      arr[pos + 3] = 255;
    }
    const current = ctx.getImageData(0, 1, width, height - 1);
    ctx.putImageData(current, 0, 0);
    ctx.putImageData(new ImageData(arr, width), 0, height - 1);

    pattern = nextPattern;
  };
  repeat(height, draw);

  const texture = new CanvasTexture(
    canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    NearestFilter,
    NearestFilter,
  );
  texture.repeat.x = rand(1, 5);
  texture.repeat.y = rand(1, 3);
  texture.rotation = randBit() ? Math.PI : 0;

  return {
    texture,
    canvas,
    step: () => {
      frame += 1;
      if (frame % frameDivisor === 0) {
        draw();
        texture.needsUpdate = true;
      }
    },
    dark: isDark([color, baseColor]),
  };
};
