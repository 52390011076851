import BigNumber from 'bignumber.js';
import React, { FC } from 'react';
import { Button } from '../../components';
import { EXCHANGE_URL } from '../../config';
import { dataStore } from '../../dataStore';
import { PlanetData, ShipData, ShipStatus } from '../../dataStore/types';
import { ChooseShipModal } from './ChooseShipModal';

export type TravelModalProps = {
  planet: PlanetData;
  defaultChosenShip?: ShipData;
  defaultCosts?: {
    distance: string;
    costPerDistance: string;
    cost: string;
    displayCost: string;
  };
  close: () => void;
};

export const TravelModal: FC<TravelModalProps> = ({
  planet,
  close,
  defaultChosenShip,
  defaultCosts,
}) => {
  const [costs, updateCosts] = React.useState<
    | {
        distance: string;
        costPerDistance: string;
        cost: string;
        displayCost: string;
      }
    | null
    | undefined
  >(defaultCosts);
  const [el69Required, updateEl69Required] = React.useState(false);
  const [chosenShip, updateChosenShip] = React.useState<
    ShipData | null | undefined
  >(defaultChosenShip);
  const [approvalRequired, updateApprovalRequired] = React.useState(false);

  return (
    <ChooseShipModal
      title={`Send ship to ${planet.traits.name}:`}
      close={close}
      loadShips={async () => {
        const address = dataStore.viewerAddress();
        if (address === null) return [];
        const ships = await dataStore.enabledShips(address);
        return ships.filter(
          ship =>
            ship.currentPlanet?.id !== planet.id &&
            ship.status === ShipStatus.ACTIVE,
        );
      }}
      onSelect={async ship => {
        const travelCost = await dataStore.travelCost(
          ship?.currentPlanet?.id as string,
          planet.id,
        );
        updateCosts(travelCost);
        updateChosenShip(ship);
      }}
    >
      {!costs && 'Choose ship'}
      {el69Required && (
        <div>
          You do not have enough EL69 to travel. The cost is{' '}
          {costs?.displayCost}.
          <div style={{ marginTop: '1rem' }}>
            <a href={EXCHANGE_URL} target="_blank" rel="noreferrer">
              Get EL69 on Uniswap
            </a>
          </div>
        </div>
      )}
      {approvalRequired && !el69Required && (
        <>
          <div style={{ marginBottom: '1rem' }}>
            You must first approve the game contract to transfer EL69 on your
            behalf.
          </div>
          <Button
            onClick={() => {
              dataStore.approveSpend();
              close();
            }}
          >
            Approve
          </Button>
        </>
      )}
      {costs && !el69Required && !approvalRequired && (
        <>
          <div style={{ marginBottom: '1rem' }}>
            It will cost {costs.displayCost} EL69 to travel to this planet.
          </div>
          <Button
            onClick={async () => {
              const balance = await dataStore.walletEL69Balance(
                dataStore.viewerAddress() as string,
              );

              if (new BigNumber(balance).times(10 ** 18).lt(costs.cost)) {
                return updateEl69Required(true);
              }

              const approvedAmount = await dataStore.getApprovedAmount(
                dataStore.viewerAddress() as string,
              );

              if (new BigNumber(costs.cost).gt(approvedAmount.toString())) {
                updateApprovalRequired(true);
                return;
              }

              dataStore.travel(chosenShip?.id as string, planet.id);

              close();
            }}
          >
            Travel
          </Button>
        </>
      )}
    </ChooseShipModal>
  );
};
