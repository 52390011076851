import { rand, randInt } from '../utils/rand';

const frequency: { [c: string]: number } = {
  a: 1 / 8.497,
  b: 1 / 1.492,
  c: 1 / 2.202,
  d: 1 / 4.253,
  e: 1 / 11.162,
  f: 1 / 2.228,
  g: 1 / 2.015,
  h: 1 / 6.094,
  i: 1 / 7.546,
  j: 1 / 0.153,
  k: 1 / 1.292,
  l: 1 / 4.025,
  m: 1 / 2.406,
  n: 1 / 6.749,
  o: 1 / 7.507,
  p: 1 / 1.929,
  q: 1 / 0.095,
  r: 1 / 7.587,
  s: 1 / 6.327,
  t: 1 / 9.356,
  u: 1 / 2.758,
  v: 1 / 0.978,
  w: 1 / 2.56,
  x: 1 / 0.15,
  y: 1 / 1.994,
  z: 1 / 0.077,
};
const weightedMember = (str: string) => {
  const letters = str.split('');
  const divisions = letters.reduce((memo, c, i) => {
    memo[i] = (memo[i - 1] || 0) + frequency[c];
    return memo;
  }, new Array(letters.length));
  const total = divisions[divisions.length - 1];
  return () => {
    const v = rand(0, total);
    let min = 0;
    let max = divisions.length - 1;
    while (max > min) {
      const mid = Math.floor((max + min) / 2);
      if (divisions[mid] < v) {
        min = mid + 1;
      } else {
        max = mid - 1;
      }
    }
    return letters[min];
  };
};
const randConsonant = weightedMember('bcdfghjklmnpqrstvwxz');
const randVowel = weightedMember('aeiouy');
const blends: { [letter: string]: () => string } = {
  b: weightedMember('lrz'),
  c: weightedMember('lrkc'),
  d: weightedMember('rwzd'),
  f: weightedMember('lrft'),
  g: weightedMember('lr'),
  k: weightedMember('r'),
  l: weightedMember('lt'),
  m: weightedMember('mnt'),
  n: weightedMember('nt'),
  p: weightedMember('prlt'),
  q: weightedMember('u'),
  r: weightedMember('rlcmpqzt'),
  s: weightedMember('hwtplksv'),
  t: weightedMember('rwhts'),
  w: weightedMember('wtpl'),
};

export const randName = (len: number = randInt(3, 8)) => {
  let w: Array<string> = new Array(len);
  let lastVowel = rand() > 0.2;
  for (let i = 0; i < len; i++) {
    if (lastVowel) {
      w[i] = randConsonant();
      if (blends[w[i]] && rand() < 0.5) {
        w[i + 1] = blends[w[i]]();
        i++;
      }
      lastVowel = false;
    } else {
      w[i] = randVowel();
      if (rand() < 0.1) {
        w[i + 1] = randVowel();
        i++;
      }
      lastVowel = true;
    }
  }
  w[0] = w[0].toUpperCase();
  return w.join('');
};
