import React, { FC } from 'react';
import { PlanetData } from '../dataStore/types';
import { ObjectList } from './ObjectList';
import { Planet } from './Planet';

export type PlanetListProps = {
  itemsPerRow: number;
  planets: PlanetData[];
  targetPlanet?: PlanetData | null;
  onHoverPlanet?: (planet: PlanetData | null) => void;
  onClickPlanet?: (planet: PlanetData) => void;
  outline?: boolean;
};

export const PlanetList: FC<PlanetListProps> = ({
  planets,
  itemsPerRow,
  targetPlanet = null,
  onHoverPlanet,
  onClickPlanet,
  outline,
}) => {
  return (
    <ObjectList
      items={planets}
      backgrounds={planets.map(({ traits: { background } }) => background)}
      itemsPerRow={itemsPerRow}
      infoHeight={0}
      target={targetPlanet}
      outline={outline}
      render3D={planet => (
        <Planet
          key={planet.id}
          {...planet.traits}
          position={[0, 0, 0]}
          scale={0.25}
          rotation={[Math.PI / 2, 0, 0]}
        />
      )}
      url={planet => `/planets/${planet.id}`}
      renderDOM={planet => <div>{planet.traits.name}</div>}
      onHover={onHoverPlanet}
      onClick={onClickPlanet}
    />
  );
};
