import React, { FC, ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';

export type ModalProps = {
  title: string;
  children?: ReactNode;
  close?: () => void;
  width?: number;
};

export const Modal: FC<ModalProps> = ({ title, children, close, width }) => {
  const shadeRef = useRef<HTMLDivElement | null>(null);

  return createPortal(
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0,0,0,0.75)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
          fontSize: '2em',
        }}
        onKeyDown={e => {
          e.stopPropagation();
          if (close && e.key === 'Escape') {
            close();
          }
        }}
      >
        <div
          style={{
            backgroundColor: 'black',
            opacity: 0.5,
          }}
          ref={shadeRef}
          onClick={e => {
            if (close && e.target === shadeRef.current) close();
          }}
        />
        <div
          style={{
            backgroundColor: 'black',
            borderRadius: '12px',
            border: 'solid 4px #fff',
            padding: '1rem',
            position: 'relative',
            zIndex: 2,
            boxSizing: 'content-box',
            width: width ?? '100%',
            maxWidth: '650px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}
          >
            <span>{title}</span>
            {close && (
              <button
                onClick={close}
                onKeyPress={e => {
                  if (e.key === 'Enter') close();
                }}
                style={{
                  marginLeft: '2rem',
                  background: 'none',
                  color: 'lime',
                  border: '1px solid lime',
                  padding: '0 10px',
                  borderRadius: '3px',

                  fontSize: '1.6rem',
                }}
              >
                ×
              </button>
            )}
          </div>
          {children}
        </div>
      </div>
    </>,
    document.body,
  );
};
