import { rand, randInt, repeat, add } from '../utils';
import { randTexture } from '../textures';
import { MoonTraits, Vector } from '../types';
import { randName } from './randName';

export const randMoon = (
  minRadius: number,
  maxRadius: number,
  minOrbitRadius: number,
  maxOrbitRadius: number,
): MoonTraits => {
  const rFactor = rand();
  const r = minRadius + rFactor * (maxRadius - minRadius);

  return {
    radius: r,
    segments: [randInt(9, 18), randInt(9, 18)],
    rotation: add(repeat(3, () => rand(-Math.PI / 4, Math.PI / 4)) as Vector, [
      Math.PI / 2,
      0,
      0,
    ]),
    texture: randTexture(),
    orbitStart: rand(0, Math.PI * 2),
    orbitPeriod: rand(45, 120),
    orbitRadius: rand(minOrbitRadius, maxOrbitRadius),
    name: randName(),
  };
};
