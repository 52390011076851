import { CanvasTexture, UVMapping, RepeatWrapping, NearestFilter } from 'three';
import { GenerativeTexture } from '../types';
import { repeat, rand, randColor, randInt, isDark } from '../utils';

export const moire = (): GenerativeTexture => {
  const size = Math.floor(rand(17, 39));
  const count = size * size;
  const numWaves = randInt(3, 8);
  const wavelengths = repeat(numWaves, () => randInt(3, 19));
  const speeds = repeat(numWaves, () => rand(-1, 1));
  const colors = repeat(numWaves, randColor);
  const baseColor = randColor();

  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const arr = new Uint8ClampedArray(4 * count);

  let frame = 0;

  const draw = () => {
    for (let i = 0; i < count; i++) {
      let [r, g, b] = baseColor;
      for (let j = 0; j < numWaves; j++) {
        if (Math.floor(i + frame * speeds[j]) % wavelengths[j] === 0) {
          [r, g, b] = colors[j];
          break;
        }
      }
      const pos = i * 4;
      arr[pos] = r;
      arr[pos + 1] = g;
      arr[pos + 2] = b;
      arr[pos + 3] = 255;
    }
    ctx.putImageData(new ImageData(arr, size), 0, 0);
  };
  draw();

  const texture = new CanvasTexture(
    canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    NearestFilter,
    NearestFilter,
  );
  texture.repeat.x = rand(1, 7);
  texture.repeat.y = rand(1, 11);
  texture.rotation = (randInt(0, 4) * Math.PI) / 2;

  const frameTime = 1 / randInt(6, 15);
  let elapsed = 0;

  return {
    texture,
    canvas,
    step: delta => {
      elapsed += delta;
      if (elapsed > frameTime) {
        frame++;
        elapsed = elapsed % frameTime;
        draw();
        texture.needsUpdate = true;
      }
    },
    dark: isDark([...colors, baseColor]),
  };
};
