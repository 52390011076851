import { useState, useEffect } from 'react';

export const useAsyncData = <Data>(
  load: () => Promise<Data>,
  key: any[] = [],
): [boolean, boolean, Data | null] => {
  const [{ loading, data, error }, setState] = useState<{
    loading: boolean;
    error: boolean;
    data: Data | null;
  }>({ loading: true, error: false, data: null });

  useEffect(() => {
    (async () => {
      setState({
        loading: true,
        error: false,
        data: null,
      });
      try {
        const nextData = await load();
        setState({
          loading: false,
          error: false,
          data: nextData,
        });
      } catch (e) {
        console.error(e);
        setState({
          loading: false,
          error: true,
          data: null,
        });
      }
    })();
  }, key);
  return [loading, error, data];
};
