import {
  DataStore,
  ForcefieldListing,
  ListingType,
  PlanetData,
  PlanetListing,
  ShipData,
  ShipListing,
  ShipStatus,
} from './types';
import {
  PLANET_CONTRACT,
  SHIP_CONTRACT,
  GAME_DATA_CONTRACT,
  GAMEPLAY_CONTRACT,
  EL69_CONTRACT,
} from '../config';
import { mockSectorPlanets } from './mockPlanets';
import { mockShips } from './mockShips';
import { web3Util } from '../utils/web3Utils';
import Web3 from 'web3';
import { randPlanet } from '../traits/randPlanet';
import { ABIS } from '../abi';
import { seed as randSeed } from '../utils';
import { shipBackground } from '../traits';
import BigNumber from 'bignumber.js';

const MIN_DELAY = 200;
const MAX_DELAY = 400;
const delayedResponse = <Data>(data: Data): Promise<Data> =>
  new Promise(resolve =>
    setTimeout(
      () => resolve(data),
      MIN_DELAY + Math.random() * (MAX_DELAY - MIN_DELAY),
    ),
  );
const randInt = (max: number, min = 0): number =>
  Math.floor(min + Math.random() * (max - min));

export function getContract(address: string) {
  const abi = ABIS[address];
  if (!web3Util.web3) throw new Error('Web3 required');
  return new web3Util.web3.eth.Contract(abi, address);
}

// @ts-ignore
window.getContract = getContract;

const enabled = (owner: string) =>
  owner.toLowerCase() == GAME_DATA_CONTRACT.toLowerCase();

export async function getPlanetDataById(
  planetId: string,
): Promise<PlanetData | null> {
  try {
    const gameDataContract = getContract(GAME_DATA_CONTRACT);
    const planetContract = getContract(PLANET_CONTRACT);

    let owner = await planetContract.methods.ownerOf(planetId).call();

    let enabledForGameplay = false;
    let miningShipId;
    if (enabled(owner)) {
      const planet = await gameDataContract.methods.planets(planetId).call();
      miningShipId = planet.miningShipId;
      owner = planet.owner;
      enabledForGameplay = true;
    }

    const [params, seedString] = await Promise.all([
      planetContract.methods.getPlanetParams(planetId).call(),
      planetContract.methods.getTokenSeed(planetId).call(),
    ]);

    const seed = new BigNumber(seedString).modulo(4294967296).toNumber();

    const {
      resourceDepth,
      biodiversity,
      albedo,
      geologicalInstability,
      groundDefenses,
      spaceDefenses,
    } = params;
    const x = parseInt(params.x) / 1000;
    const y = parseInt(params.y) / 1000;

    return {
      id: planetId,
      seed,
      stats: {
        resourceDepth,
        biodiversity,
        albedo,
        geologicalInstability,
        groundDefenses,
        spaceDefenses,
      },
      location: [x, y],
      sector: Math.floor(y / 10) * 10 + Math.floor(x / 10) + 1,
      enabledForGameplay,
      owner,
      miningShipId,
      traits: randPlanet(seed),
    };
  } catch {
    return null;
  }
}

async function getShipDataById(shipId: string): Promise<ShipData> {
  if (!web3Util.web3) throw new Error('Web3 required');
  const contract = getContract(SHIP_CONTRACT);
  // eslint-disable-next-line prefer-const
  let [layers, params, owner, seed] = await Promise.all([
    contract.methods.getLayers(shipId).call(),
    contract.methods.getShipParams(shipId).call(),
    contract.methods.ownerOf(shipId).call(),
    contract.methods.getTokenSeed(shipId).call(),
  ]);

  let enabledForGameplay = false;

  const gameDataContract = getContract(GAME_DATA_CONTRACT);

  let shipData;
  let status = ShipStatus.ACTIVE;
  let unclaimedRewards = null;
  let currentTime;
  let disabledUntil;
  if (enabled(owner)) {
    shipData = await gameDataContract.methods.ships(shipId).call();

    const { timestamp } = await web3Util.web3.eth.getBlock('latest');
    owner = shipData.owner;
    enabledForGameplay = true;
    unclaimedRewards = 0;

    if (shipData.mining) {
      status = ShipStatus.MINING;
    }
    // TODO: Get block time to compare rather than Date.now()
    if (shipData.travelingUntil > timestamp) {
      status = ShipStatus.TRANSIT;
    }

    if (shipData.disabledUntil > timestamp) {
      currentTime = timestamp;
      disabledUntil = shipData.disabledUntil;
      status = ShipStatus.DISABLED;
    }
    try {
      unclaimedRewards = (
        await gameDataContract.methods.calcRewards(shipId).call()
      ).toString();
    } catch {
      // no op
    }
  }

  let currentPlanet: PlanetData | null = null;
  if (enabledForGameplay) {
    const { planetId } = shipData;
    currentPlanet = await getPlanetDataById(planetId);
  }

  const {
    fuelEfficiency,
    groundWeapons,
    labCapacity,
    laserStrength,
    spaceWeapons,
    speed,
    toolStrength,
    blastersLength,
    blastersWidth,
    bodyLength,
    bodyWidth,
    color1,
    color2,
    color3,
    color4,
  } = params;

  const shipConfig = {
    params: {
      blastersLength,
      blastersWidth,
      bodyLength,
      bodyWidth,
      color1,
      color2,
      color3,
      color4,
    },
    layers,
  };

  const background = shipBackground(shipConfig);

  const stats = {
    fuelEfficiency,
    groundWeapons,
    labCapacity,
    laserStrength,
    spaceWeapons,
    speed,
    toolStrength,
  };

  randSeed(seed);
  return {
    id: shipId,
    shipConfig,
    stats,
    status,
    owner,
    currentTime,
    disabledUntil,
    enabledForGameplay,
    currentPlanet,
    unclaimedRewards,
    location: currentPlanet?.location,
    background,
  };
}

export class Store implements DataStore {
  private _viewerAddress: string | null = null;
  private _pendingTransactions: Set<string> = new Set();
  private _transactionStatusChangeHandlers: Set<(hashes: string[]) => void> =
    new Set();

  constructor() {
    web3Util.on('accountsUpdated', ([connectedWallet]) => {
      this._viewerAddress = connectedWallet;
    });

    // TODO: Need to handle people without a wallet
    web3Util.enable().then(() => {
      const pollTx = web3Util.pollTx;
      if (pollTx === undefined)
        throw new Error('unable to access polltx, wallet must be connected');
      pollTx.on('completed', (completedHash: string) => {
        this._pendingTransactions.delete(completedHash);
        const transactions = [...this._pendingTransactions];
        this._transactionStatusChangeHandlers.forEach(handler =>
          handler(transactions),
        );
      });
    });
  }

  beginTransaction(
    contractAddress: string,
    methodName: string,
    args: any[],
  ): Promise<string> {
    const contract = getContract(contractAddress);
    const method = contract.methods[methodName];

    if (method === undefined)
      throw new Error(`Cant get method ${methodName} on ${contractAddress}`);

    return new Promise<string>(resolve => {
      method(...args)
        .send({ from: this._viewerAddress })
        .on('transactionHash', (hash: string) => {
          const pollTx = web3Util.pollTx;
          if (pollTx === undefined)
            throw new Error(
              'unable to access polltx, wallet must be connected',
            );

          this._pendingTransactions = this._pendingTransactions.add(hash);
          pollTx.watch(hash);

          const transactions = [...this._pendingTransactions];
          this._transactionStatusChangeHandlers.forEach(handler =>
            handler(transactions),
          );

          resolve(hash);
        })
        .on('error', (e: unknown) => {
          console.log(e);
        });
    });
  }

  // event handling
  onTransactionStatusChange(fn: (hashes: string[]) => void) {
    this._transactionStatusChangeHandlers.add(fn);
  }
  offTransactionStatusChange(fn: (hashes: string[]) => void) {
    this._transactionStatusChangeHandlers.delete(fn);
  }

  // read viewer data
  viewerAddress() {
    return this._viewerAddress;
  }

  // read global contract data
  async globalMiningPower() {
    const contract = getContract(GAME_DATA_CONTRACT);
    const total = await contract.methods.totalMiningPower().call();

    return total.toString();
  }
  async rewardPoolBalance() {
    const el69 = getContract(EL69_CONTRACT);
    const balance = await el69.methods.balanceOf(GAME_DATA_CONTRACT).call();
    return new BigNumber(balance.toString()).div(10 ** 18).toNumber();
  }

  getClaimableAmountByShipId(shipId: string): Promise<string> {
    const contract = getContract(GAME_DATA_CONTRACT);
    return contract.methods.getClaimableAmount(shipId).call();
  }

  async getBonusAmount(address: string): Promise<string> {
    const contract = getContract(GAME_DATA_CONTRACT);
    return contract.methods.bonuses(address).call();
  }

  // function to get approved amount of el69 for game.
  getApprovedAmount(address: string) {
    const contract = getContract(EL69_CONTRACT);
    return contract.methods.allowance(address, GAME_DATA_CONTRACT).call();
  }

  // read wallet data
  async walletEL69Balance(_address: string) {
    const contract = getContract(EL69_CONTRACT);
    const balance = await contract.methods.balanceOf(_address).call();
    return Web3.utils.fromWei(balance);
  }
  walletTotalMiningPower(_address: string) {
    return delayedResponse(randInt(0, 10000));
  }
  walletBonusBalance(_address: string) {
    return delayedResponse(randInt(0, 10000));
  }

  // ships and planets owned and enabled for gameplay
  async enabledPlanets(_address: string) {
    const contract = getContract(GAME_DATA_CONTRACT);
    const balance = await contract.methods
      .getTotalOwnedPlanets(_address)
      .call();
    const out = [];

    for (let i = 0; i < parseInt(balance.toString()); i++) {
      const fn = async () => {
        const id = await contract.methods
          .getOwnedPlanetByIndex(_address, i)
          .call();
        let data;
        try {
          data = await getPlanetDataById(id);
        } catch (e) {
          console.log(e);
        }
        return data;
      };
      out.push(fn());
    }
    const planets = await Promise.all(out);
    const filtered = planets.filter(planet => planet);
    return filtered as PlanetData[];
  }

  async enabledShips(_address: string) {
    const contract = getContract(GAME_DATA_CONTRACT);
    const balance = await contract.methods.getTotalOwnedShips(_address).call();
    const out = [];

    for (let i = 0; i < parseInt(balance.toString()); i++) {
      const fn = async () => {
        const id = await contract.methods
          .getOwnedShipByIndex(_address, i)
          .call();
        return getShipDataById(id);
      };
      out.push(fn());
    }
    return Promise.all(out);
  }

  // ships and planets owned and not enabled for gameplay
  async walletPlanets(_address: string) {
    const contract = getContract(PLANET_CONTRACT);
    const balance = await contract.methods.balanceOf(_address).call();
    const out = [];

    for (let i = 0; i < parseInt(balance.toString()); i++) {
      const fn = async () => {
        const id = await contract.methods
          .tokenOfOwnerByIndex(_address, i)
          .call();
        return getPlanetDataById(id);
      };
      out.push(fn());
    }
    const planets = await Promise.all(out);
    return planets.filter(planet => planet) as PlanetData[];
  }
  async walletShips(_address: string) {
    const contract = getContract(SHIP_CONTRACT);
    const balance = await contract.methods.balanceOf(_address).call();
    const out = [];

    for (let i = 0; i < parseInt(balance.toString()); i++) {
      const fn = async () => {
        const shipId = await contract.methods
          .tokenOfOwnerByIndex(_address, i)
          .call();
        return getShipDataById(shipId);
      };
      out.push(fn());
    }
    return Promise.all(out);
  }

  // ships and planets by location
  async sectorShips(sector: number) {
    const planetContract = getContract(PLANET_CONTRACT);
    const ids = await planetContract.methods.getPlanetsInSector(sector).call();
    return Promise.all(
      ids.map((id: BigNumber) => this.planetShips(id.toString())),
    );
  }
  async sectorPlanets(sector: number) {
    const planetContract = getContract(PLANET_CONTRACT);
    const ids = await planetContract.methods.getPlanetsInSector(sector).call();
    const data = await Promise.all(
      ids.map((id: BigNumber) => getPlanetDataById(id.toString())),
    );
    return data.filter(data => data);
  }
  async planetShips(_planetId: string) {
    const dataContract = getContract(GAME_DATA_CONTRACT);
    const numberOfShipsOnPlanets = await dataContract.methods
      .getTotalShipsOnPlanet(_planetId)
      .call();
    const out = [];
    for (let i = 0; i < numberOfShipsOnPlanets; i++) {
      const fn = async () => {
        const shipId = await dataContract.methods
          .getShipOnPlanetByIndex(_planetId, i)
          .call();
        return this.ship(shipId);
      };
      out.push(fn());
    }

    return Promise.all(out);
  }

  // ships and planets by id
  planet(planetId: string) {
    return getPlanetDataById(planetId);
  }
  async ship(shipId: string) {
    return getShipDataById(shipId);
  }

  // action costs
  async travelCost(planetA: string, planetB: string) {
    const contract = getContract(GAME_DATA_CONTRACT);
    const [distance, costPerDistance] = (
      await Promise.all([
        contract.methods.getDistance(planetA, planetB).call(),
        contract.methods.PRICE_PER_DISTANCE().call(),
      ])
    ).map(v => v.toString());
    const cost = new BigNumber(distance).times(costPerDistance);
    return {
      distance,
      costPerDistance,
      cost: cost.toFixed(),
      displayCost: cost.div(10 ** 18).toFixed(4, 1),
    };
  }

  async shipMetadata(shipID: string): Promise<any> {
    try {
      const contract = getContract(SHIP_CONTRACT);
      const dataURI = await contract.methods.tokenURI(shipID).call();
      const jsonString = new Buffer(dataURI.split(',')[1], 'base64').toString();
      return JSON.parse(jsonString);
    } catch (e: any) {
      console.log('Error getting ship metadata');
      console.log(e.message);
    }
  }

  // async getClaimEventsForAddress(address: string): Promise<
  //   {
  //     transactionHash: string;
  //     returnValues: {
  //       owner: string;
  //       pirate: string;
  //       planetId: string;
  //       success: boolean;
  //     };
  //   }[]
  // > {
  //   const data = getContract(GAME_DATA_CONTRACT);

  //   const events = await data.getPastEvents('Pirated', {
  //     filter: { pirate: [address] },
  //     fromBlock: 'earliest',
  //     toBlock: 'latest',
  //   });
  //   const res = await fetch(
  //     `https://api.polygonscan.com/api?module=logs&action=getLogs&fromBlock=26836229&toBlock=latest&address=0x05D209b3fb8E47E3AA2d2622A008a421Dc32b65a&topic0=0xe171ace56fcce62b8a021bd0e0c6e0c7bfa85dc3a588a6d01fb4998e7a4be4b2&topic1=${web3Util.web3?.utils.padLeft(
  //       address,
  //       64,
  //     )}&apikey=318H4DJ4SXAPKX347DN1PV289RB8KYDQPU`,
  //   ).then(r => r.json());
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   return events.map(event => {
  //     return {
  //       transactionHash: event.transactionHash,
  //       returnValues: event.returnValues,
  //     };
  //   });
  // }

  async getPirateEventsForAddress(address: string): Promise<
    {
      transactionHash: string;
      returnValues: {
        owner: string;
        pirate: string;
        planetId: string;
        success: boolean;
      };
    }[]
  > {
    try {
      const res = await fetch(
        `https://api.polygonscan.com/api?module=logs&action=getLogs&fromBlock=26836229&toBlock=latest&address=0x05D209b3fb8E47E3AA2d2622A008a421Dc32b65a&topic0=0xe171ace56fcce62b8a021bd0e0c6e0c7bfa85dc3a588a6d01fb4998e7a4be4b2&topic1=${web3Util.web3?.utils.padLeft(
          address,
          64,
        )}&apikey=318H4DJ4SXAPKX347DN1PV289RB8KYDQPU`,
      ).then(r => r.json());
      // @ts-ignore
      let events = res?.result ?? [];
      events = events.map((log: any) => {
        const returnValues = web3Util.web3?.eth?.abi.decodeLog(
          [
            { type: 'address', indexed: true, name: 'pirate' },
            { type: 'address', indexed: true, name: 'owner' },
            { type: 'uint256', indexed: true, name: 'planetId' },
            { type: 'bool', name: 'success' },
          ],
          log.data,
          log.topics.slice(1),
        );
        return {
          returnValues,
          transactionHash: log?.transactionHash,
        };
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return events.map(event => {
        return {
          transactionHash: event.transactionHash,
          returnValues: event.returnValues,
        };
      });
    } catch {
      return [];
    }
  }

  pirateCost(shipId: string, planetId: string): Promise<number> {
    const contract = getContract(GAME_DATA_CONTRACT);
    return contract.methods.getStealPrice(shipId, planetId).call();
  }
  miningPower(shipId: string, planetId: string): Promise<number> {
    const contract = getContract(GAME_DATA_CONTRACT);
    return contract.methods.calculateMiningPower(shipId, planetId).call();
  }

  // mutate contract data
  async claimBonus() {
    alert('mocked wallet interaction');
    return '';
  }

  // Must be called after the approvePlanets function is called
  enablePlanetForGameplay(planetId: string) {
    return this.beginTransaction(GAMEPLAY_CONTRACT, 'addPlanet', [planetId]);
  }
  enableShipForGameplay(shipId: string, planetId: string) {
    return this.beginTransaction(GAMEPLAY_CONTRACT, 'addShip', [
      shipId,
      planetId,
    ]);
  }
  disablePlanetForGameplay(planetId: string) {
    return this.beginTransaction(GAMEPLAY_CONTRACT, 'removePlanet', [planetId]);
  }
  disableShipForGameplay(shipId: string) {
    return this.beginTransaction(GAMEPLAY_CONTRACT, 'removeShip', [
      shipId,
      false,
    ]);
  }
  mine(shipId: string) {
    return this.beginTransaction(GAMEPLAY_CONTRACT, 'beginMining', [shipId]);
  }
  stopMining(shipId: string) {
    return this.beginTransaction(GAMEPLAY_CONTRACT, 'stopMining', [
      shipId,
      false,
    ]);
  }
  withdrawMiningRewards(shipId: string, risky: boolean) {
    return this.beginTransaction(GAMEPLAY_CONTRACT, 'claim', [shipId, risky]);
  }
  approveSpend() {
    return this.beginTransaction(EL69_CONTRACT, 'approve', [
      GAME_DATA_CONTRACT,
      new BigNumber(99999999999).times(10 ** 18).toFixed(),
    ]);
  }
  pirate(shipId: string, planetId: string, quotedPrice: string) {
    return this.beginTransaction(GAMEPLAY_CONTRACT, 'pirate', [
      shipId,
      planetId,
      quotedPrice,
    ]);
  }
  travel(shipId: string, planetId: string) {
    return this.beginTransaction(GAMEPLAY_CONTRACT, 'travel', [
      shipId,
      planetId,
    ]);
  }

  approvePlanets() {
    return this.beginTransaction(PLANET_CONTRACT, 'setApprovalForAll', [
      GAME_DATA_CONTRACT,
      true,
    ]);
  }
  approveShips() {
    return this.beginTransaction(SHIP_CONTRACT, 'setApprovalForAll', [
      GAME_DATA_CONTRACT,
      true,
    ]);
  }
  arePlanetsApproved() {
    const contract = getContract(PLANET_CONTRACT);
    return contract.methods
      .isApprovedForAll(this._viewerAddress, GAME_DATA_CONTRACT)
      .call();
  }
  areShipsApproved() {
    const contract = getContract(SHIP_CONTRACT);
    return contract.methods
      .isApprovedForAll(this._viewerAddress, GAME_DATA_CONTRACT)
      .call();
  }

  // marketplace
  marketplaceShips(_page: number, perPage: number): Promise<ShipListing[]> {
    return delayedResponse(
      mockShips.slice(0, perPage).map(item => ({
        price: randInt(10000, 1000),
        item,
        type: ListingType.SHIP,
      })),
    );
  }
  marketplacePlanets(_page: number, perPage: number): Promise<PlanetListing[]> {
    return delayedResponse(
      mockSectorPlanets.slice(0, perPage).map(item => ({
        price: randInt(10000, 1000),
        item,
        type: ListingType.PLANET,
      })),
    );
  }
  marketplaceForceFields(
    _page: number,
    perPage: number,
  ): Promise<ForcefieldListing[]> {
    return delayedResponse(
      new Array(perPage).fill(0).map((_, i) => ({
        price: randInt(10000, 1000),
        item: {
          id: i + 1,
          owner: '0x5C14Ba32530d76019a0fFDe7E98c9BD32b4B6b02',
        },
        type: ListingType.FORCEFIELD,
      })),
    );
  }
  async sell(_type: ListingType, _id: string, _price: number) {}
  async buy(_type: ListingType, _id: string) {}
  async delist(_type: ListingType, _id: string) {}

  // minting planets
  async mintPrice() {
    return 60000;
  }
  async planetsAvailableToMint() {
    return 8888;
  }
  async approveEL69ForPlanetMint() {
    alert('mock contract interaction');
    return '0xabcdef';
  }
  async mint(_number: number) {
    alert('mock contract interaction');
    return '0xabcdef';
  }
  showWelcomeMessage() {
    return !localStorage?.getItem('DISMISS_PXG_WELCOME');
  }
}
