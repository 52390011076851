export const isDark = (colors: [number, number, number][]): boolean => {
  return (
    colors.reduce(
      (memo, [r, g, b]) =>
        memo + Math.sqrt(r * r + g * g + b * b) / Math.sqrt(3),
      0,
    ) /
      colors.length <
    128
  );
};
