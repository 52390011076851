import { Vector } from '.';

export enum CellValue {
  ON,
  BORDER,
  OFF,
}

export type ShipLayer = {
  matrix: CellValue[][];
  fillColor: string | null;
  borderColor: string | null;
  yOffset?: number;
};

export type ShipTraits = {
  shipLayers: ShipLayer[];
  blasterLayers: ShipLayer[];
  dimensions: {
    blasters: {
      l: number;
      w: number;
    };
    body: {
      l: number;
      w: number;
    };
  };
  size: Vector;
  maxVelocity: number;
  accelleration: number;
  fireRate: number;
  bulletVelocity: number;
};

type BodyLayer = string;
type BlasterLayer = string;

export type ShipConfig = {
  params: {
    blastersLength: string;
    blastersWidth: string;
    bodyLength: string;
    bodyWidth: string;
    color1: string;
    color2: string;
    color3: string;
    color4: string;
  };
  layers: [BodyLayer, BodyLayer, BlasterLayer, BlasterLayer, BlasterLayer];
};
