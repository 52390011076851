export const PLANET_CONTRACT = '0x81C8122e3De6744E0D2c97e49B72B768526D4AE6';
export const SHIP_CONTRACT = '0x2b08172532f11ce6dee44ffa9ab999769faae7ec';
export const GAME_DATA_CONTRACT = '0x05D209b3fb8E47E3AA2d2622A008a421Dc32b65a';
export const GAMEPLAY_CONTRACT = '0xBc04D675dE7B4C1BBD374D99B1570D6054b9d6c7';
export const EL69_CONTRACT = '0xAC05696A96fbF947200876ABb8e71b9e18Ef3D6A';
export const LP_CONTRACT = '';
export const MARKET_CONTRACT = '';
export const POLYGONSCAN_URI = 'https://polygonscan.com/tx/';
export const EXCHANGE_URL =
  'https://app.sushi.com/trident/swap?tokens=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&tokens=0xAC05696A96fbF947200876ABb8e71b9e18Ef3D6A';

// RINKEBY
// export const PLANET_CONTRACT = '0x1C68676fA461d396FaAaD0754C2AA0c6FbAea756';
// export const SHIP_CONTRACT = '0xB112E2915C98C61a3f70fce996eAD8ef25dfCd08';
// export const GAME_DATA_CONTRACT = '0x6FFc4FaD678c2ca8e26e55d48D3dF12c05E1a1bC';
// export const GAMEPLAY_CONTRACT = '0xcE72814C93823b158a67644dB6879AEdfD45caF7';
// export const EL69_CONTRACT = '0x53292E3f24Bce3d03FBefC5Bc7959CA5E0939A32';
// export const LP_CONTRACT = '';
// export const MARKET_CONTRACT = '';
// export const POLYGONSCAN_URI = 'https://polygonscan.com/tx/';
// export const EXCHANGE_URL =
//   'https://app.sushi.com/trident/swap?tokens=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&tokens=0xAC05696A96fbF947200876ABb8e71b9e18Ef3D6A';

// 0x2D2C46D26c8FEc693586e067c4fC2b2DDC2b4cb3

// ORIGINAL PRODUCTION VALUES W BUG do not delete
// export const PLANET_CONTRACT = '0x81C8122e3De6744E0D2c97e49B72B768526D4AE6';
// export const SHIP_CONTRACT = '0x2b08172532f11ce6dee44ffa9ab999769faae7ec';
// export const GAME_DATA_CONTRACT = '0xAc6F729D70d329C5966051b4aC0995E8C3927be2';
// export const GAMEPLAY_CONTRACT = '0x08984e6613a9014B01414D3F932856D4fcbC67fc';
// export const EL69_CONTRACT = '0xAC05696A96fbF947200876ABb8e71b9e18Ef3D6A';
// export const LP_CONTRACT = '';
// export const MARKET_CONTRACT = '';
// export const POLYGONSCAN_URI = 'https://polygonscan.com/tx/';
// export const EXCHANGE_URL =
//   'https://app.sushi.com/trident/swap?tokens=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&tokens=0xAC05696A96fbF947200876ABb8e71b9e18Ef3D6A';

// MIGRATION TX https://polygonscan.com/tx/0x3613dbfb3d09d47c894db1263a681d9df8c809ee5ba28510dae8c461d82dee89
// 115050509.3135903128
// Withdraw link https://polygonscan.com/tx/0x8a426a178654103d5ef724cb122c6074c5ed1049689b9a1117b1bf545fb0481c
