import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Lights } from './Lights';
import { TargetOutline } from './TargetOutline';
import { BoxGeometry } from 'three';
import { useFrame } from '@react-three/fiber';
import { GenerativeTexture } from '../types';

const cellSize = 40;

export type ObjectListSceneProps<Item> = {
  items: Item[];
  backgrounds: GenerativeTexture[];
  render3D: (item: Item) => ReactNode;
  itemsPerRow: number;
  infoHeight: number;
  target: Item | null;
  outline?: boolean;
};

export const ObjectListScene = <Item,>({
  items,
  infoHeight,
  backgrounds,
  render3D,
  itemsPerRow,
  target,
  outline,
}: ObjectListSceneProps<Item>): ReactElement => {
  const numRows = Math.ceil(items.length / itemsPerRow);
  const itemsRef = useRef<Map<Item, any>>(new Map());
  const geometry = useMemo(
    () => new BoxGeometry(cellSize, 1, cellSize + infoHeight),
    [infoHeight],
  );
  useEffect(
    () => () => {
      geometry.dispose();
    },
    [geometry],
  );
  useFrame((_, delta) => {
    if (target != null) {
      const i = items.indexOf(target);
      backgrounds[i].step(delta);
    }
  });

  return (
    <>
      <Lights />
      {items.map((item, i) => (
        <mesh
          key={i}
          position={[
            ((i % itemsPerRow) - (itemsPerRow - 1) / 2) * cellSize,
            100,
            ((numRows - 1) / 2 - Math.floor(i / itemsPerRow)) *
              (cellSize + infoHeight),
          ]}
          geometry={geometry}
          visible={target === item}
        >
          <meshStandardMaterial
            map={backgrounds[i].texture}
            attach="material"
          />
        </mesh>
      ))}
      {items.map((item, i) => (
        <group
          key={i}
          ref={el => {
            itemsRef.current.set(item, el);
          }}
          position={[
            ((i % itemsPerRow) - (itemsPerRow - 1) / 2) * cellSize,
            0,
            ((numRows - 1) / 2 - Math.floor(i / itemsPerRow)) *
              (cellSize + infoHeight) +
              infoHeight / 2,
          ]}
        >
          {render3D(item)}
        </group>
      ))}
      {outline && (
        <TargetOutline
          selectedObjects={
            target != null && itemsRef.current.has(target)
              ? [itemsRef.current.get(target)]
              : []
          }
        />
      )}
    </>
  );
};
