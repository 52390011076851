import React, { FC, MouseEventHandler, ReactNode, useContext } from 'react';
import { RoutingContext } from '../contexts';

export type LinkProps = {
  href: string;
  children: ReactNode;
  className?: string;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  onClick?: MouseEventHandler;
};

export const Link: FC<LinkProps> = ({
  href,
  children,
  className,
  onMouseEnter,
  onMouseLeave,
  onClick,
}) => {
  const { route } = useContext(RoutingContext);

  return (
    <a
      href={href}
      onClick={e => {
        if (e.metaKey || e.button !== 0) return;
        e.preventDefault();
        if (onClick) onClick(e);
        else route(href);
      }}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </a>
  );
};
