import { randPlanet } from '../traits/randPlanet';
import { repeat } from '../utils';
import { PlanetData } from './types';

const randPlanetData = (i: number): PlanetData => {
  const seed = Math.random() * 4294967296;
  return {
    id: `${i}`,
    seed,
    location: [-50 + Math.random() * 100, -50 + Math.random() * 100],
    sector: 1,
    traits: randPlanet(seed),
    stats: {
      resourceDepth: `${Math.floor(Math.random() * 4000)}`,
      biodiversity: `${Math.floor(Math.random() * 4000)}`,
      albedo: `${Math.floor(Math.random() * 4000)}`,
      geologicalInstability: `${Math.floor(Math.random() * 4000)}`,
      groundDefenses: `${Math.floor(Math.random() * 4000)}`,
      spaceDefenses: `${Math.floor(Math.random() * 4000)}`,
    },
    owner: '0x5C14Ba32530d76019a0fFDe7E98c9BD32b4B6b02',
    enabledForGameplay: true,
  };
};

export const mockUserPlanets: PlanetData[] = repeat(10, randPlanetData);

export const mockSectorPlanets: PlanetData[] = repeat(100, randPlanetData);
