import React, { FC, useState } from 'react';
import { LoadingIndicator } from '../../components';
import { Button } from '../../components/Button';
import { StatusBarPageLayout } from '../../components/StatusBarPageLayout';
import { dataStore } from '../../dataStore';
import { ListingType } from '../../dataStore/types';
import { useAsyncData } from '../../utils/useAsyncData';

export type MarketplacePageProps = {};

const PAGE_SIZE = 20;

export const MarketplacePage: FC<MarketplacePageProps> = () => {
  const [{ tab, page }, setTabAndPage] = useState<{
    tab: ListingType;
    page: number;
  }>({
    tab: ListingType.SHIP,
    page: 0,
  });

  const [loading, error, data] = useAsyncData(async () => {
    switch (tab) {
      case ListingType.SHIP:
        return dataStore.marketplaceShips(page, PAGE_SIZE);
      case ListingType.PLANET:
        return dataStore.marketplacePlanets(page, PAGE_SIZE);
      case ListingType.FORCEFIELD:
        return dataStore.marketplaceForceFields(page, PAGE_SIZE);
      default:
        throw new Error('unexpected listing type');
    }
  }, [tab]);

  if (loading || error || data === null) {
    return <LoadingIndicator error={error} />;
  }

  return (
    <StatusBarPageLayout
      breadcrumbs={[
        ['INVADERS!', '/starmap'],
        ['Marketplace', '/marketplace'],
      ]}
      content={
        <div
          style={{
            padding: '2rem',
          }}
        >
          <h1>Marketplace!</h1>
          <div>
            <Button
              onClick={() => setTabAndPage({ tab: ListingType.SHIP, page: 0 })}
            >
              Ships
            </Button>
            <Button
              onClick={() =>
                setTabAndPage({ tab: ListingType.PLANET, page: 0 })
              }
            >
              Planets
            </Button>
            <Button
              onClick={() =>
                setTabAndPage({ tab: ListingType.FORCEFIELD, page: 0 })
              }
            >
              Force fields
            </Button>
          </div>
          {data.map(d => (
            <div>{d.item.id}</div>
          ))}
        </div>
      }
    />
  );
};
