import {
  EL69_CONTRACT,
  GAMEPLAY_CONTRACT,
  GAME_DATA_CONTRACT,
  PLANET_CONTRACT,
  SHIP_CONTRACT,
} from '../config';

import data from './data.json';
import element69 from './element69.json';
import gameplay from './gameplay.json';
import planets from './planets.json';
import ships from './ships.json';

export const ABIS: { [address: string]: any } = {
  [GAME_DATA_CONTRACT]: data,
  [EL69_CONTRACT]: element69,
  [GAMEPLAY_CONTRACT]: gameplay,
  [PLANET_CONTRACT]: planets,
  [SHIP_CONTRACT]: ships,
};
