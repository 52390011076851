import React, { FC } from 'react';
import { ThreeEvent, useFrame } from '@react-three/fiber';
import { Vector, GenerativeTexture } from '../types';

export type CanvasPlaneProps = {
  texture: GenerativeTexture;
  position: Vector;
  size: [number, number];
  transparent: boolean;
  color?: number;
  meshRef?: (el: any) => void;
  onClick?: (e: ThreeEvent<MouseEvent>) => void;
  onPointerDown?: (e: ThreeEvent<PointerEvent>) => void;
  onPointerUp?: (e: ThreeEvent<PointerEvent>) => void;
  onPointerMove?: (e: ThreeEvent<PointerEvent>) => void;
  onPointerLeave?: (e: ThreeEvent<PointerEvent>) => void;
  side?: any;
};

export const CanvasPlane: FC<CanvasPlaneProps> = ({
  texture,
  position,
  size,
  meshRef,
  onClick,
  onPointerMove,
  onPointerDown,
  onPointerUp,
  onPointerLeave,
  transparent,
  color,
  side,
}) => {
  useFrame((_, delta) => {
    texture.step(delta);
  });
  return (
    <mesh
      position={position}
      ref={meshRef}
      onClick={onClick}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
      onPointerLeave={onPointerLeave}
    >
      <planeBufferGeometry attach="geometry" args={size} />
      <meshStandardMaterial
        map={texture.texture}
        attach="material"
        transparent={transparent}
        color={color}
        side={side}
      />
    </mesh>
  );
};
