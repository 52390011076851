import BigNumber from 'bignumber.js';
import React, { useContext } from 'react';
import { TxModalContext } from '../../App';
import {
  Button,
  LoadingIndicator,
  StatusBarPageLayout,
} from '../../components';
import { EL69_CONTRACT, PLANET_CONTRACT } from '../../config';
import { dataStore } from '../../dataStore';
import { getContract } from '../../dataStore/Store';

const PRICE = new BigNumber(150000).times(10 ** 18);

export default function MintPlanet() {
  const [loading, updateLoading] = React.useState(true);
  const { nonce } = useContext(TxModalContext);
  const [totalAvailable, updateTotalAvailable] = React.useState<
    number | undefined
  >();
  const [approvalRequired, updateApprovalRequired] = React.useState(false);
  React.useEffect(() => {
    const fn = async () => {
      updateLoading(true);
      updateApprovalRequired(false);
      const planets = getContract(PLANET_CONTRACT);
      const el69 = getContract(EL69_CONTRACT);
      const totalPlanets = await planets.methods.totalSupply().call();
      const available = new BigNumber(10000).minus(totalPlanets.toString());
      const allowance = await el69.methods
        .allowance(dataStore.viewerAddress(), PLANET_CONTRACT)
        .call();
      if (new BigNumber(allowance.toString()).lt(PRICE)) {
        updateApprovalRequired(true);
      }
      updateTotalAvailable(available.toNumber());
      updateLoading(false);
    };
    fn();
  }, [nonce]);

  const approveEl69 = async () => {
    const el69 = getContract(EL69_CONTRACT);
    const balance = await el69.methods
      .balanceOf(dataStore.viewerAddress())
      .call();
    if (new BigNumber(balance.toString()).lt(PRICE)) {
      alert('Insufficient EL69 balance');
      return;
    }
    await dataStore.beginTransaction(EL69_CONTRACT, 'approve', [
      PLANET_CONTRACT,
      new BigNumber(99999999999).times(10 ** 18).toFixed(),
    ]);
  };

  const mintPlanet = async () => {
    await dataStore.beginTransaction(PLANET_CONTRACT, 'buyWithEl69', [1]);
  };

  return (
    <StatusBarPageLayout
      content={
        <div
          style={{ padding: '0 2rem 6rem', maxWidth: '1000px', margin: 'auto' }}
        >
          {loading && <LoadingIndicator error={false} />}
          {!loading && typeof totalAvailable == 'number' && (
            <>
              <h2>Mint Planets</h2>
              <p>You can mint new planets using EL69.</p>
              <p>Total available: {totalAvailable}</p>
              <p>Price: 150,000 EL69</p>
              {totalAvailable == 0 && <p>No planets currently available.</p>}
              {totalAvailable > 0 && (
                <>
                  {approvalRequired && (
                    <Button
                      onClick={() => approveEl69()}
                      style={{ width: '100%', maxWidth: '250px' }}
                    >
                      Approve EL69
                    </Button>
                  )}
                  {!approvalRequired && (
                    <Button
                      onClick={() => mintPlanet()}
                      style={{ width: '100%', maxWidth: '250px' }}
                    >
                      Mint
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </div>
      }
      breadcrumbs={[['INVADERS!', '/starmap']]}
    />
  );
}
