import React, { MouseEvent, ReactElement, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { OrthographicCamera } from 'three';
import { ObjectListScene } from './ObjectListScene';
import { GenerativeTexture } from '../types';
import { repeat, useResizeObserver } from '../utils';
import { Link } from './Link';

const CellLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 1.5rem;
  padding: 1rem;
  text-decoration: none;
`;

const cellSize = 40;

const Container = styled.div`
  position: relative;
`;
const Grid = styled.div`
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export type ObjectListProps<Item> = {
  itemsPerRow: number;
  infoHeight: number;
  items: Item[];
  backgrounds: GenerativeTexture[];
  target: Item | null;
  render3D: (item: Item) => ReactNode;
  renderDOM: (item: Item) => ReactNode;
  url: (item: Item) => string;
  onHover?: (item: Item | null) => void;
  onClick?: (item: Item) => void;
  outline?: boolean;
};

export const ObjectList = <Item,>({
  itemsPerRow,
  infoHeight,
  items,
  target,
  backgrounds,
  render3D,
  renderDOM,
  url,
  onHover,
  onClick,
  outline,
}: ObjectListProps<Item>): ReactElement => {
  const [ref, rect] = useResizeObserver<HTMLDivElement>();

  let numRows: number = 0;
  let width: number = 0;
  let height: number = 0;
  let infoHeightThreeUnits: number = 0;
  let aspectRatio: number = 0;
  if (rect) {
    numRows = Math.ceil(items.length / itemsPerRow);
    width = itemsPerRow * cellSize;
    infoHeightThreeUnits = (infoHeight * cellSize * itemsPerRow) / rect.width;
    height = numRows * (cellSize + infoHeightThreeUnits);
    aspectRatio = width / height;
  }

  const camera = useMemo(() => {
    if (rect === null) return;
    const c = new OrthographicCamera(
      -width / 2,
      width / 2,
      height / 2,
      -height / 2,
      1,
      1000,
    );
    c.position.set(0, -100, 0);
    c.lookAt(0, 0, 0);
    c.updateMatrix();
    return c;
  }, [width, height]);

  if (rect === null) {
    return <Container ref={ref} />;
  }

  return (
    <Container
      ref={ref}
      style={{
        aspectRatio: `${aspectRatio}`,
      }}
    >
      <Canvas camera={camera}>
        <ObjectListScene
          items={items}
          backgrounds={backgrounds}
          itemsPerRow={itemsPerRow}
          infoHeight={infoHeightThreeUnits}
          render3D={render3D}
          target={target}
          outline={outline}
        />
      </Canvas>
      <Grid
        style={{
          gridTemplateColumns: repeat(itemsPerRow, () => '1fr').join(' '),
        }}
      >
        {items.map((item, i) => (
          <CellLink
            key={i}
            href={url(item)}
            onMouseEnter={onHover && (() => onHover(item))}
            onMouseLeave={onHover && (() => onHover(null))}
            onClick={
              onClick &&
              ((e: MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                onClick(item);
              })
            }
          >
            {renderDOM(item)}
          </CellLink>
        ))}
      </Grid>
    </Container>
  );
};
