import { CanvasTexture, UVMapping, RepeatWrapping, NearestFilter } from 'three';
import { GenerativeTexture } from '../types';
import { rand, randInt } from '../utils';

export const bytePattern = (): GenerativeTexture => {
  const width = Math.floor(rand(10, 40));
  const height = Math.floor(rand(10, 40));
  const patternLength = Math.floor(rand(7, 87));
  const pattern = new Array(patternLength).fill(0).map(() => rand());
  const rMult = rand(0, 256);
  const gMult = rand(0, 256);
  const bMult = rand(0, 256);

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const arr = new Uint8ClampedArray(4 * width * height);

  let offset = 0;
  let reset = Math.floor(rand(51, 201));
  const draw = () => {
    for (let i = 0, j = offset; i < arr.length; i += 4, j += 3) {
      arr[i + 0] = Math.floor(rMult * pattern[(j % reset) % patternLength]); // R value
      arr[i + 1] = Math.floor(
        gMult * pattern[((j + 1) % reset) % patternLength],
      ); // G value
      arr[i + 2] = Math.floor(
        bMult * pattern[((j + 2) % reset) % patternLength],
      ); // B value
      arr[i + 3] = 255;
    }
    ctx.putImageData(new ImageData(arr, width), 0, 0);
  };
  draw();

  const texture = new CanvasTexture(
    canvas,
    UVMapping,
    RepeatWrapping,
    RepeatWrapping,
    NearestFilter,
    NearestFilter,
  );
  texture.repeat.x = rand(2, 7);
  texture.repeat.y = rand(1.5, 5);
  texture.rotation = (randInt(0, 4) * Math.PI) / 2;

  const frameTime = 1 / 10;
  let elapsed = 0;

  return {
    texture,
    canvas,
    step: delta => {
      elapsed += delta;
      if (elapsed > frameTime) {
        elapsed = elapsed % frameTime;

        offset += 1;
        reset = reset > 400 ? 51 : reset + 1;
        draw();
        texture.needsUpdate = true;
      }
    },
    dark: (rMult + gMult + bMult) / 3 < 128,
  };
};
