import React, { FC, useRef } from 'react';
import { CanvasBackground, Lights, FastShip } from '../../components';
import { useCamera } from '../../utils';
import { useFrame } from '@react-three/fiber';
import { ShipData } from '../../dataStore/types';

export type SceneProps = { ship: ShipData };

export const Scene: FC<SceneProps> = ({ ship }) => {
  const ref = useRef<any>();

  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.rotation.y += delta / 10;
      ref.current.position.z = Math.sin(state.clock.elapsedTime) / 2;
    }
  });

  useCamera([0, -30, 5], [0, 0, 5], 60);

  return (
    <>
      <Lights />
      <CanvasBackground texture={ship.background} />
      <FastShip
        groupRef={el => (ref.current = el)}
        shipConfig={ship.shipConfig}
        rotation={[1.9, 0, 0]}
      />
    </>
  );
};
