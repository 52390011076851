import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, LoadingOverlay, Modal, ShipList } from '../../components';
import { dataStore } from '../../dataStore';
import { ShipData } from '../../dataStore/types';
import { useAsyncData } from '../../utils/useAsyncData';

export type ChooseShipModalProps = {
  title: string;
  close: () => void;
  loadShips: () => Promise<ShipData[]>;
  onSelect: (ship: ShipData) => void;
  children?: React.ReactNode;
  renderInfo?: (ship: ShipData | null) => {
    initial: string;
    final?: Promise<string>;
  };
};

export const ChooseShipModal: FC<ChooseShipModalProps> = ({
  title,
  close,
  loadShips,
  onSelect,
  renderInfo,
  children,
}) => {
  const [targetShip, setTargetShip] = useState<ShipData | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const infoGeneration = useRef(0);
  useEffect(() => {
    if (renderInfo) {
      const { initial, final } = renderInfo(null);
      setInfo(initial);
      final?.then(info => {
        if (infoGeneration.current === 0) setInfo(info);
      });
    }
  }, []);
  const address = dataStore.viewerAddress();

  const [loading, error, ships] = useAsyncData(loadShips, [address]);
  if (loading || error || ships === null) {
    return <LoadingOverlay style={{ maxHeight: '300px' }} />;
  }

  return (
    <Modal title={title} close={close}>
      {ships?.length > 0 && (
        <ShipList
          ships={ships}
          itemsPerRow={5}
          targetShip={targetShip}
          onHoverShip={ship => {
            setTargetShip(ship);
            infoGeneration.current++;
            const g = infoGeneration.current;
            if (renderInfo) {
              const { initial, final } = renderInfo(
                ship === null ? null : ship,
              );
              setInfo(initial);
              final?.then(info => {
                if (infoGeneration.current === g) setInfo(info);
              });
            }
          }}
          onClickShip={(ship: ShipData) => {
            onSelect(ship);
          }}
          outline
        />
      )}
      <div style={{ marginTop: '1rem' }}>
        {children && <>{children}</>}
        {!children && <>{info === null ? '...' : info}</>}
      </div>
      {ships?.length === 0 && (
        <div style={{ marginTop: '1rem' }}>
          You have not enabled any ships. Choose ships to enable from your{' '}
          <Link href={`/wallets/${address}`}>wallet page</Link>.
        </div>
      )}
    </Modal>
  );
};
