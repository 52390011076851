import React, { FC } from 'react';
import { dataStore } from '../../dataStore';
import { PlanetData, ShipStatus } from '../../dataStore/types';
import { ChooseShipModal } from './ChooseShipModal';

export type MineModalProps = {
  planet: PlanetData;
  close: () => void;
};

export const MineModal: FC<MineModalProps> = ({ planet, close }) => {
  return (
    <ChooseShipModal
      title={`Mine ${planet.traits.name}:`}
      close={close}
      loadShips={async () => {
        const address = dataStore.viewerAddress();
        if (address === null) return [];
        const ships = await dataStore.enabledShips(address);
        return ships.filter(
          ship =>
            ship.currentPlanet?.id === planet.id &&
            ship.status === ShipStatus.ACTIVE,
        );
      }}
      onSelect={ship => {
        dataStore.mine(ship.id);
        close();
      }}
      renderInfo={ship => {
        if (ship === null) return { initial: 'Choose ship' };
        return {
          initial: `Star Ship #${ship.id}`,
          final: (async () => {
            const miningPower = await dataStore.miningPower(ship.id, planet.id);
            return `Star Ship #${ship.id} - mining power ${miningPower}`;
          })(),
        };
      }}
    />
  );
};
