import { FC } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { GenerativeTexture } from '../types';
import { useEffect } from 'react';

export type CanvasBackgroundProps = {
  texture: GenerativeTexture;
};

export const CanvasBackground: FC<CanvasBackgroundProps> = ({ texture }) => {
  const { scene } = useThree();
  useEffect(() => {
    scene.background = texture.texture;
  }, [texture, scene]);
  useFrame((_, delta) => texture.step(delta));
  return null;
};
