import React, { FC, useState } from 'react';
import { LoadingIndicator, Modal } from '.';
import { Button } from './Button';

export type ApproveTransferModalProps = {
  close: () => void;
  transferItem: string;
  onConfirm: () => Promise<void>;
};

export const ApproveTransferModal: FC<ApproveTransferModalProps> = ({
  close,
  transferItem,
  onConfirm,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal title="Transaction 1 of 2" close={close}>
      {loading ? (
        <LoadingIndicator
          style={{ height: 'auto', width: 'auto' }}
          error={false}
        />
      ) : (
        <>
          <p>
            You are first required to approve the game contract to transfer{' '}
            {transferItem} on your behalf. You only need to do this once.
          </p>
          <div>
            <Button
              onClick={async () => {
                setLoading(true);
                await onConfirm();
              }}
            >
              Approve
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
