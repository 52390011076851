import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { BoxGeometry, Material, MeshStandardMaterial } from 'three';
import { CellValue, ShipConfig, Vector } from '../types';
import { getMatrixFromId, countNeighbors } from '../utils';

export type FastShipProps = {
  position?: Vector;
  rotation?: Vector;
  scale?: number;
  shipConfig: ShipConfig;
  groupRef?: (el: any) => void;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
  onClick?: () => void;
};

export const FastShip: FC<FastShipProps> = ({
  shipConfig,
  position,
  rotation,
  scale,
  groupRef,
  onPointerEnter,
  onPointerLeave,
  onClick,
}) => {
  const allLayers = useMemo(() => {
    return shipConfig?.layers.map((layer, i) => {
      const bitMatrix =
        i < 2
          ? getMatrixFromId(
              layer,
              shipConfig.params.bodyLength,
              shipConfig.params.bodyWidth,
            )
          : getMatrixFromId(
              layer,
              shipConfig.params.blastersLength,
              shipConfig.params.blastersWidth,
            );

      return bitMatrix.map((row, y) =>
        row.map((v, x) =>
          v
            ? CellValue.ON
            : countNeighbors(bitMatrix, x, y) > 0
            ? CellValue.BORDER
            : CellValue.OFF,
        ),
      );
    });
  }, [shipConfig]);

  const geometry = useMemo(() => new BoxGeometry(), [shipConfig]);
  const [color1, color2, color3, color4] = useMemo(
    () => [
      new MeshStandardMaterial({ color: shipConfig.params.color1 }),
      new MeshStandardMaterial({ color: shipConfig.params.color2 }),
      new MeshStandardMaterial({ color: shipConfig.params.color3 }),
      new MeshStandardMaterial({ color: shipConfig.params.color4 }),
    ],
    [shipConfig],
  );
  useEffect(
    () => () => {
      geometry.dispose();
      color1.dispose();
      color2.dispose();
      color3.dispose();
      color4.dispose();
    },
    [shipConfig],
  );

  const voxels = useMemo(
    () =>
      allLayers.map((matrix, z) => {
        const isBase = z < 2;
        const fillColor = color1;
        let borderColor: Material;
        if (z === 0 || z === 2) {
          // c2
          borderColor = color2;
        } else if (z === 1 || z === 3) {
          //c3
          borderColor = color3;
        } else {
          // c4
          borderColor = color4;
        }
        const bodyL = parseInt(shipConfig.params.bodyLength);
        let yOffset = isBase ? 7 : 0;
        const size = 10;

        return isBase ? (
          <Fragment key={z}>
            {matrix.map((row, y) =>
              row.map((v, x) => {
                const color =
                  v === CellValue.ON
                    ? fillColor
                    : v === CellValue.BORDER
                    ? borderColor
                    : null;

                return (
                  color && (
                    <Fragment key={`${x}_${y}`}>
                      <mesh
                        position={[x - size, y - bodyL / 2 + yOffset, 0]}
                        geometry={geometry}
                        material={color}
                      />
                      <mesh
                        position={[size - 1 - x, y - bodyL / 2 + yOffset, 0]}
                        geometry={geometry}
                        material={color}
                      />
                      <mesh
                        position={[x - size, y - bodyL / 2 + yOffset, 2]}
                        geometry={geometry}
                        material={color}
                      />
                      <mesh
                        position={[size - 1 - x, y - bodyL / 2 + yOffset, 2]}
                        geometry={geometry}
                        material={color}
                      />
                    </Fragment>
                  )
                );
              }),
            )}
          </Fragment>
        ) : (
          <Fragment key={z}>
            {matrix.map((row, y) =>
              row.map((v, x) => {
                const color =
                  v === CellValue.ON
                    ? fillColor
                    : v === CellValue.BORDER
                    ? borderColor
                    : null;

                return (
                  color && (
                    <Fragment key={`${x}_${y}`}>
                      <mesh
                        position={[x - size, y, 0]}
                        geometry={geometry}
                        material={color}
                      />
                      <mesh
                        position={[size - 1 - x, y, 0]}
                        geometry={geometry}
                        material={color}
                      />
                      <mesh
                        position={[x - size, y, 1]}
                        geometry={geometry}
                        material={color}
                      />
                      <mesh
                        position={[size - 1 - x, y, 1]}
                        geometry={geometry}
                        material={color}
                      />
                      <mesh
                        position={[x - size, y, 3]}
                        geometry={geometry}
                        material={color}
                      />
                      <mesh
                        position={[size - 1 - x, y, 3]}
                        geometry={geometry}
                        material={color}
                      />
                    </Fragment>
                  )
                );
              }),
            )}
          </Fragment>
        );
      }),
    [shipConfig],
  );

  return (
    <group
      position={position}
      rotation={rotation}
      ref={groupRef}
      scale={scale}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onClick={onClick}
    >
      {voxels}
    </group>
  );
};
