import React, { FC } from 'react';
import { dataStore } from '../../dataStore';
import { AddressedWalletPage } from '../WalletPage/AddressedWalletPage';

export type HomePageProps = {};

export const HomePage: FC<HomePageProps> = () => {
  const address = dataStore.viewerAddress();
  return address === null ? null : <AddressedWalletPage address={address} />;
};
