import React, { FC } from 'react';
import { AddressedWalletPage } from './AddressedWalletPage';

export type WalletPageProps = {
  path: string[];
};

export const WalletPage: FC<WalletPageProps> = ({ path: [, address] }) => {
  return <AddressedWalletPage address={address} />;
};
