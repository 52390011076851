import React, { FC } from 'react';
import { FastShip } from './FastShip';
import { dataStore } from '../dataStore';
import { ShipData } from '../dataStore/types';
import { ObjectList } from './ObjectList';

export type ShipListProps = {
  itemsPerRow: number;
  ships: ShipData[];
  targetShip: ShipData | null;
  onHoverShip?: (ship: ShipData | null) => void;
  onClickShip?: any;
  outline?: boolean;
};

export const ShipList: FC<ShipListProps> = ({
  ships,
  itemsPerRow,
  targetShip,
  onHoverShip,
  onClickShip,
  outline,
}) => {
  return (
    <ObjectList
      items={ships}
      backgrounds={ships.map(({ background }) => background)}
      itemsPerRow={itemsPerRow}
      infoHeight={48}
      target={targetShip}
      outline={outline}
      onClick={onClickShip}
      render3D={ship => (
        <FastShip
          key={ship.id}
          shipConfig={ship.shipConfig}
          position={[0, 1, -6]}
          rotation={[Math.PI / 2 - 0.5, -0.3, 0]}
        />
      )}
      url={ship => `/ships/${ship.id}`}
      renderDOM={ship => (
        <>
          <div>#{ship.id}</div>
          <div>{ship.status}</div>
          <div>
            {ship.owner === dataStore.viewerAddress()
              ? 'Yours'
              : `${ship.owner.slice(0, 6)}...`}
          </div>
        </>
      )}
      onHover={onHoverShip}
    />
  );
};
