import React, { FC } from 'react';
import { StatusBarPageLayout } from '../../components/StatusBarPageLayout';

export type PowerupsPageProps = {};

export const PowerupsPage: FC<PowerupsPageProps> = () => {
  return (
    <StatusBarPageLayout
      breadcrumbs={[
        ['INVADERS!', '/starmap'],
        ['Powerups', '/powerups'],
      ]}
      content={
        <div
          style={{
            padding: '2rem',
          }}
        >
          <h1>Powerups</h1>
        </div>
      }
    />
  );
};
