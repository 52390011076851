import { bytePattern } from './bytePattern';
import { hyperspace } from './hyperspace';
import { life } from './life';
import { moire } from './moire';
import { rule30 } from './rule30';
import { rule90 } from './rule90';
import { rule184 } from './rule184';
import { starfield } from './starfield';
import { zebra } from './zebra';

import { randMember } from '../utils';
import { GenerativeTexture } from '../types';

export * from './bytePattern';
export * from './hyperspace';
export * from './life';
export * from './moire';
export * from './starfield';
export * from './rule30';
export * from './rule90';
export * from './rule184';
export * from './zebra';
export * from './powerUp';
export * from './sector';
export * from './sectors';

export const textures: { [key: string]: () => GenerativeTexture } = {
  bytePattern,
  moire,
  zebra,
  hyperspace,
  starfield,
  life,
  rule30,
  rule90,
  rule184,
  // lifeRGB,
  // rule184RGB,
  // rule30RGB,
};

export const randTexture = () => randMember(Object.values(textures))();
