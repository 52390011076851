import styled from 'styled-components';
import React, { ChangeEvent } from 'react';

const Input = styled.input`
  border: 3px solid white;
  padding: 5px;
  background: #000;
  color: lime;
  font-size: 42px;
  font-family: monogram;
`;

type TextFieldProps = {
  onChange: (val: string) => any;
  value: string;
};

export function TextField({ onChange, value }: TextFieldProps) {
  return (
    <Input
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      value={value}
      type="text"
    />
  );
}
